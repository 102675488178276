
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import gsap from "gsap";
import Content_Model from '@/nextts/model/Content_Model';
import { useContentStore } from "@/stores/content";
import Play_Api from './Play_Api';
import { emitter } from '@/emitter';
import Play_Sound from './Play_Sound';

//기본 데이터 속성
class Play_Text extends HTMLElement implements Http_Interface, Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;
    
    contentStore = useContentStore();
    private updateTextHandler:any;
    private updateTextClickHandler:any;
    private updateTextTouchHandler:any;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
    
    }
    
    //Play_Interface 구현
    init(): void {
        
        //파이어베이스 변경 감지 처리
        if(this.model != null && this.model.api_use){
            this.updateTextHandler = this.updatetext_handler.bind(this);
            emitter.on('updatetext', this.updateTextHandler);
        }

        //이벤트 처리
        if(this.model?.event_use){
            if(this.model.event_type >= 1){
                //클릭 리스너 붙이기
                this.updateTextClickHandler = this.handleClick.bind(this);
                this.updateTextTouchHandler = this.handleTouchStart.bind(this);
                this.addEventListener('click',      this.updateTextClickHandler);
                this.addEventListener('touchstart', this.updateTextTouchHandler);                
            }
        }
        

        //컴포넌트 시작
        this.playAni();

    }

    handleClick(event: MouseEvent){
        this.clickevent();
    }
    
    handleTouchStart(event: TouchEvent){
        this.clickevent();
    }    

    clickevent(){

        if(this.model?.event_type == 1){
            
            //페이지 이동 - 전체 컨텐츠에서 페이지 이름을 찾아서 이동한다.
            for(let i=0; i < this.contentStore.content_model.screen_list.length; i++){
                const screen= this.contentStore.content_model.screen_list[i];
                if(this.model.event_move == screen.screen_name){
                    console.log("move screen name : " + screen.screen_name);
                    emitter.emit('movepage', i);
                    break;
                }
            }


        }else if(this.model?.event_type == 2){
            //api 호출 - 현재 스크린에서 api를 찾아서 호출한다.
            if(this.contentStore.select_screen == null) return;
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_API && this.model.event_api == component.apiname){
                    
                    const api_component = component.play_component as Play_Api;
                    api_component.runApiAwait();
                    break;

                }
            }

        }else if(this.model?.event_type == 3){
            //타이핑 - 현재 스크린에서 text 타겟을 찾아서 입력한다.
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_key == null || this.model.event_input_key == undefined || this.model.event_input_key.length == 0){
                return;
            }
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText += this.model.event_input_key;

                        //저장
                        if(component.save_use){
                            Content_Model.apiData.set(String(component.save_name), component.play_component.innerText);
                        }

                        break;
                    }
                }
            }
        }else if(this.model?.event_type == 4){

            //1글자 지우기
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }

            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = component.play_component.innerText.slice(0, -1);

                        //저장
                        if(component.save_use){
                            Content_Model.apiData.set(String(component.save_name), component.play_component.innerText);
                        }

                        break;
                    }
                }
            }


        }else if(this.model?.event_type == 5){
            //모두 지우기
            if(this.contentStore.select_screen == null) return;
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = "";

                        //저장
                        if(component.save_use){
                            Content_Model.apiData.set(String(component.save_name), component.play_component.innerText);
                        }

                        break;
                    }
                }
            }

        }else if(this.model?.event_type == 6){
            //타이핑 - 교체
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_key == null || this.model.event_input_key == undefined || this.model.event_input_key.length == 0){
                return;
            }
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = String(this.model.event_input_key);

                        //저장
                        if(component.save_use){
                            Content_Model.apiData.set(String(component.save_name), component.play_component.innerText);
                        }

                        break;
                    }
                }
            }


        }else if(this.model?.event_type == 20){
            //사운드 재생
            if(this.contentStore.select_screen == null) return;
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_SOUND && this.model.event_input_target == component.event_name){
                    
                    const api_component = component.play_component as Play_Sound;
                    api_component.soundplayevent();
                    break;

                }
            }


        }        

    }

    updatetext_handler(){

        console.log("update text...");

        if(this.model == null || this.model == undefined){
            return;
        }
        
        if(this.model.api_use){
            
            //api 사용이면 맵핑값 가져오기
            if(Content_Model.apiData.has(this.model.apiname)){
                const resultdata = Content_Model.apiData.get(this.model.apiname);

                //path로 전체 리절트 값에서 꺼내기.
                if(this.model.apiarray.length > 0){
                    const dataary = this.getValueByPath(resultdata, this.model.apiarray) as any;    
                    if(dataary != null && dataary != undefined){
                        this.innerText = dataary;
                    }else{
                        this.innerText = "";
                    }
                }else{
                    //apiarray가 없으면
                    if(resultdata != null && resultdata != undefined){
                        this.innerText = resultdata;
                    }else{
                        this.innerText = "";
                    }
                }
                
            }else{
                this.innerText   = "";    
            }

            //저장
            if(this.model.save_use){
                Content_Model.apiData.set(String(this.model.save_name), this.innerText);
            }

        }else{
            this.innerText  = this.model.text;
        }

    }

    //json값에서 path에 해당하는 값을 꺼냄.
    getValueByPath<T>(json: Record<string, any>, path: string): T | undefined {
        //const parts = path.match(/\w+/g) || []; // 경로 문자열에서 키 이름 추출
        //return parts.reduce((acc: any, part: string) => acc && acc[part], json) as T;

        // 대괄호 안의 내용을 추출하는 정규표현식
        const parts = path.match(/\[([^\]]+)\]/g) || [];
        return parts.reduce((acc: any, part: string) => {
            // 대괄호를 제거하고 실제 키값만 추출
            const key = part.replace(/\[|\]/g, '');
            // 숫자로 변환할 수 있는 키는 배열의 인덱스로, 그렇지 않은 키는 객체의 키로 처리
            return acc && (acc.hasOwnProperty(key) ? acc[key] : acc[Number(key)]);
        }, json) as T;

    }

    exit(): void {
        
        if(this.model != null && this.model.api_use){
            console.log("remove updatetext handler");
            emitter.off('updatetext', this.updateTextHandler);
        }

        if(this.model?.event_use){
            if(this.model.event_type >= 1){
                //클릭 리스너 붙이기
                this.removeEventListener('click',       this.updateTextClickHandler);
                this.removeEventListener('touchstart',  this.updateTextTouchHandler);                
            }
        }
    
        //효과음 초기화 (일단 패스)

        //타이머 초기화

        //스위처 초기화

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            //첫번째 delay는 여기서 사용했기 때문에 runAni에서는 첫번째 deplay는 무조건 0으로 처리해야한다.
            setTimeout(() => {
               this.drawView();
               this.runAni();
            }, this.model.aniplaymodel.anilist[0].delay * 1000);

        }else{
            //애니 없음
            this.drawView();
        }

    }

    //애니매이션 실행
    runAni(){
        //console.log("Play Text run ani");
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);
        
        let move_rotatex = 0;
        let move_rotatey = 0;
        let move_skewx   = 0;
        let move_skewy   = 0;

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            move_rotatex += Number(ani.rotatex);
            move_rotatey += Number(ani.rotatey);
            move_skewx += Number(ani.skewx);
            move_skewy += Number(ani.skewy);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                                                            , scaleX:ani.scalex, scaleY:ani.scaley
                                                            , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                                                            , skewX:move_skewx, skewY:move_skewy        
                                                            , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
            
        });        

    }


    drawView(){

        //console.log("================ Play_Text drawView ===================");
        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.width            = this.model.width+"px";
        this.style.height           = this.model.height+"px";
        this.style.opacity          = String(this.model.opacity);


        if(this.model.api_use){
            //api 사용이면 맵핑값 가져오기

            if(Content_Model.apiData.has(this.model.apiname)){
                const resultdata = Content_Model.apiData.get(this.model.apiname);

                //path로 전체 리절트 값에서 꺼내기.
                if(this.model.apiarray.length > 0){
                    const dataary = this.getValueByPath(resultdata, this.model.apiarray) as any;    
                    if(dataary != null && dataary != undefined){
                        this.innerText = dataary;
                    }else{
                        this.innerText = "";
                    }
                }else{
                    //apiarray가 없으면
                    if(resultdata != null && resultdata != undefined){
                        this.innerText = resultdata;
                    }else{
                        this.innerText = "";
                    }
                }

                //저장
                if(this.model.save_use){
                    Content_Model.apiData.set(String(this.model.save_name), this.innerText);
                }

            }
        }else{
            this.innerText          = this.model.text;
        }
        

        this.style.fontFamily   = this.model.textfont;
        this.style.fontSize     = this.model.textsize+'px';
        this.style.color        = this.model.textcolor;
        this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
        //텍스트 세로 정렬
        this.style.display          = "flex";
        this.style.flexDirection    = "column";
        this.style.lineHeight       = "120%";
        this.style.justifyContent   = this.model.textverti_align;       //top, center, end
        
        this.style.letterSpacing    = this.model.letterSpacing+"px" 
        this.style.lineHeight       = this.model.lineHeight+"%"     
        
        //언더라인
        if(this.model.textUnderline == 0){
            this.style.textDecoration = "none";
        }else{
            this.style.textDecoration = "underline";
        }
        
        //볼드
        if(this.model.textBold == 0){
            this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
        }else{
            this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
        }

        //이탤릭
        if(this.model.textItalic == 0){
            this.style.fontStyle = "normal";
        }else{
            this.style.fontStyle = "italic";
        }
        
        //그림자 우측, 아래, 블러범위, 색상
        if(this.model.shadow_use){
            this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
        }else{
            this.style.textShadow = "";
        }

        //외각선 처리
        if(this.model.outline_use){
            this.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
        }else{
            this.style.webkitTextStroke = "";
        }        

        this.style.backgroundClip  = "";
        this.style.webkitBackgroundClip = "";
        this.style.backgroundImage = "";
        this.style.color = this.model.textcolor;
        this.style.webkitTextFillColor = this.model.textcolor;

        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

        
    }

}

export default Play_Text
customElements.define('play-text', Play_Text);