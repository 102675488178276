
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import gsap from "gsap";

//기본 데이터 속성
class Play_YouTube extends HTMLIFrameElement implements Http_Interface, Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;

    //이미지 마스크용
    base64String?:null|string;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
        this.style.overflow = "hidden";
        this.setAttribute("frameborder", "0");
        this.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
        this.setAttribute('allowfullscreen', '')
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Text init");
        //커스텀 api등을 통해야 하는 경우에는 분기하고

        if(this.model == null) return;
        this.drawView();
        const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        const videoId = this.getYoutubeVideoId(this.model.youtube);
        if(videoId){
            //재생 가능한 URL이면 유튜브 재생 - 자동재생, 컨트롤패널 없음
            if(this.model.loop){
                this.setAttribute('src', `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&loop=1&playlist=${videoId}`);
            }else{
                this.setAttribute('src', `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0`);
            }
        }

        //컴포넌트 시작
        this.playAni();

    }

    exit(): void {
        
        //효과음 초기화 (일단 패스)

        //타이머 초기화

        //스위처 초기화

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        this.setAttribute('src', ``);

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            setTimeout(() => {
               //this.drawView();
               this.runAni();
            }, this.model.aniplaymodel.anilist[0].delay * 1000);
        }else{
            //애니 없음
            //this.drawView();
        }

    }

    //애니매이션 실행
    runAni(){
        //console.log("Play Text run ani");
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);
        
        let move_rotatex = 0;
        let move_rotatey = 0;
        let move_skewx   = 0;
        let move_skewy   = 0;

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            move_rotatex += Number(ani.rotatex);
            move_rotatey += Number(ani.rotatey);
            move_skewx += Number(ani.skewx);
            move_skewy += Number(ani.skewy);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                                                            , scaleX:ani.scalex, scaleY:ani.scaley
                                                            , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                                                            , skewX:move_skewx, skewY:move_skewy        
                                                            , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
        });        

    }


    async drawView(){

        //console.log("================ Play_Text drawView ===================");
        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "black";
        this.style.backgroundImage  = "";
        this.style.backgroundRepeat = "no-repeat";
        this.style.width            = this.model.width+"px";
        this.style.height           = this.model.height+"px";
        this.style.opacity          = String(this.model.opacity);
        this.innerText              = "";
        

        //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
        if(this.model.mask_use){
            this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
            this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
            if(this.model.mask_repeat){
                this.style.webkitMaskRepeat = "repeat";    
            }else{
                this.style.webkitMaskRepeat = "no-repeat";    
            }
            if(this.model.mask_image.length > 0){
                if(this.model.mask_image.toLowerCase().startsWith("http")){
                    //인터넷에 업로드된 이미지면
                    try {
                        if(this.base64String == null){
                            this.base64String = await this.convertImageToBase64(this.model.mask_image);
                        }
                        this.style.webkitMaskImage = `url(${this.base64String})`;    
                    } catch (error) {
                        console.error("Error during image conversion to Base64:", error);
                    }                        
                }else{
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }
            }else{
                this.style.webkitMaskImage = "";    
            }
        }else{
            this.style.webkitMaskImage = "";
            this.style.webkitMaskPosition = "";
            this.style.webkitMaskSize = "";
        }

        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }

    //이미지 마스크 CORS 우회 처리
    loadMaskImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // CORS 정책 준수를 위해 필요
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
            img.src = url;
        });
    }

    convertImageToBase64(url: string): Promise<string> {
        return this.loadMaskImageAsync(url).then((img: HTMLImageElement) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Unable to get canvas context');
            }
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            return canvas.toDataURL('image/png');
        }).catch(error => {
            console.error("Error in converting image to Base64:", error);
            throw error;
        });
    }        

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

        
    }


    //유튜브 URL에서 비디오 URL만 가져오기
    getYoutubeVideoId(url:string):string|null {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return null;
        }
    }    

}

export default Play_YouTube
customElements.define('play-youtube', Play_YouTube, {extends:'iframe'});