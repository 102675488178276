<!-- 해당 창은 어드민 관리자가 애니매이션을 생성하는 창 -->
<template>
    <v-container fluid class="pa-0 ma-0" style="overflow:hidden;min-width:240px">

        <!-- 우측 애니매이션 인포 (컴포넌트용) -->
        <v-row>
            <v-dialog hide-overlay persistent v-model="effect_dialog" max-width="500px" >
                <v-card>
                <v-card-title>Animation Setting</v-card-title>
                <v-card-text class="ma-0 pl-5 pr-5 pt-3">
                    <v-container>

                        <v-row align="center">
                            <v-col cols="6" align="center" class="ma-0 pa-0">
                                <label style="font-size:12px">Effect Name</label>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                <input 
                                    v-model="animodel.aniname" 
                                    style="padding-left:6px;height:26px;width:210px;font-size:12px;border: solid 1px gray;border-radius:3px;"
                                    />
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Wait</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.delay" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Duration</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.duration" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">X</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.movex" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Y</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.movey" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                        

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">CenterX</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.centerx" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">CenterY</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.centery" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                        



                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="4">
                                <comeditslider :object="animodel" title="RolateX" property="rotatex" :min="-360" :max="360" :step="1" />
                            </v-col>
                            <v-col cols="4">
                                <comeditslider :object="animodel" title="RolateY" property="rotatey" :min="-360" :max="360" :step="1" />
                            </v-col>
                            <v-col cols="4">
                                <comeditslider :object="animodel" title="RolateZ" property="rotate" :min="-360" :max="360" :step="1" />
                            </v-col>
                        </v-row>

                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="SkewX" property="skewx" :min="-360" :max="360" :step="1" />
                            </v-col>
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="SkewY" property="skewy" :min="-360" :max="360" :step="1" />
                            </v-col>
                        </v-row>


                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="ScaleX" property="scalex" :min="0.1" :max="10" :step="0.01" />
                            </v-col>
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="ScaleY" property="scaley" :min="0.1" :max="10" :step="0.01" />
                            </v-col>
                        </v-row>

                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="Opacity" property="alpha" :min="0" :max="1" :step="0.01" />
                            </v-col>
                            <v-col cols="6">
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Ease</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="animodel.ease"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="cptype in easelist" :key="cptype.name">
                                                <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                                            </template>
                                        </select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Out</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="animodel.easetype"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="cptype in easetype" :key="cptype.name">
                                                <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                                            </template>
                                        </select>                                        
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6"><v-divider/></v-row>

                        <!-- 커스텀 속성 추가 -->
                        <v-row align="center" class="mt-3" >
                            <v-col cols="6">
                                <label style="width:50px;text-align:center;font-size:12px;font-weight:bold">Custom Property</label>
                            </v-col>
                            <v-col cols="6">
                                <v-btn @click="addprop" density="compact" size="small" variant="text" style="font-size:11px">Add</v-btn>
                            </v-col>
                        </v-row>

                        <v-row align="center" v-for="prop in animodel.proplist" :key="prop">

                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Prop Type</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="prop.proptype"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="prtype in proptype" :key="prtype.name">
                                                <option style="font-size:12px" :value="prtype.value">{{prtype.name}}</option>
                                            </template>
                                        </select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" >
                            </v-col>

                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Property</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <input 
                                            v-model="prop.propname" 
                                            style="width:100%;margin-left:0px;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Value</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <input 
                                            v-model="prop.propvalue" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- number 속성 -->
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >                                    
                                        <label style="font-size:12px">Min</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0">
                                        <input
                                            v-model="prop.propmin" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >                                    
                                        <label style="font-size:12px">Max</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0">
                                        <input
                                            v-model="prop.propmax" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >
                                        <label style="font-size:12px">Step</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0" >
                                        <input 
                                            v-model="prop.propstep" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-divider class="mt-1"/>
                        </v-row>
                        

                    </v-container>
                </v-card-text>

                <v-card-actions class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="effect_dialog = false">취소</v-btn>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save_effect">확인</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

                </v-card>
            </v-dialog>  
        </v-row>        

        <v-row class="mt-6" style="margin-top:0px;background:#f0f0f0;height:40px;" align="center" justify="center">
            <span style="font-size:11px;font-weight:bold">ANIMATION SETTING</span>
        </v-row>
        <v-row dense justify="center" align="center" class="mt-4">
            <v-col cols="11" align="center">
                <v-btn variant="outlined" style="font-size:11px" @click="updateaniplay">SAVE ANIMATION</v-btn>
            </v-col>
        </v-row>

        <!-- 
        <v-row align="center" dense style="margin-left:10px;margin-right:10px">
            <v-col cols="12">
                <label style="font-size:12px">Animation Type</label>
            </v-col>
            <v-col cols="12" align="center" class="d-flex">
                <select 
                    v-model="aniplay_model.category_idx"
                    style="width:100%;height:26px; border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                    <template v-for="cptype in categoryList" :key="cptype.CATEGORY_IDX">
                        <option style="font-size:12px" :value="cptype.CATEGORY_IDX">{{cptype.CATEGORY_NAME}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>
        -->
        
        <v-row align="center" dense style="margin-left:10px;;margin-right:10px">
            <v-col cols="12">
                <label style="font-size:12px">Animation Name</label>
            </v-col>
            <v-col cols="12">
                <input 
                    v-model="aniplay_model.aniplayname" 
                    style="padding-left:6px;height:26px;width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;"
                    />
            </v-col>
        </v-row>

        <v-row justify="start" align="center" dense class="mt-3" style="margin-left:10px;">
            <label style="width:70px;text-align:right;font-size:12px;margin-right:10px">Repeat</label>
            <input 
                v-model="aniplay_model.repeat" 
                @change="changerepeat" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>
        <v-row justify="start" align="center" dense class="mt-2" style="margin-left:10px;">
            <label style="width:70px;text-align:center;font-size:12px;margin-right:10px">Repeat Wait</label>
            <input 
                v-model="aniplay_model.repeatDelay" 
                @change="changedelay" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>
        <v-row justify="start" align="center" dense class="mt-2" style="margin-left:10px;">
            <label style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Reverse</label>
            <input type="checkbox" v-model="aniplay_model.yoyo"/>
        </v-row>

        <v-row justify="center" dense class="mt-3">
            <v-col cols="11">
                <v-divider/>        
            </v-col>
        </v-row>

        <v-row align="center" class="mt-1">
            <v-col cols="12" align="center">
                <v-btn style="font-size:11px" variant="tonal" @click="new_effect"
                    prepend-icon="mdi-transfer-down"
                    >Add Effect
                </v-btn>
            </v-col>
        </v-row>

        <v-row justify="center" dense class="mt-3">
            <v-col cols="12">
                <v-divider/>        
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pa-0" dense>
            <v-col cols="5">
                <div style="font-size:12px">Effect List</div>
            </v-col>
            <v-col cols="3" align="center">
                <v-btn @click="anilistplay" density="compact" size="small" height="26" variant="outlined" style="font-size:11px">PLAY</v-btn>
            </v-col>
            <v-col cols="3" align="center">
                <v-btn @click="aniliststop" density="compact" size="small" height="26" variant="outlined" style="font-size:11px">STOP</v-btn>
            </v-col>
        </v-row>

        <!-- ani list -->
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense>
            <draggable
              :list="aniplay_model.anilist"
              class="pa-0 ma-0"
              ghost-class="ghost" 
              >
              <template #item="{ element }">
                  <v-row align="center" dense class="pa-0 ma-0">
                    <v-col cols="7">
                      <td class="tbline" style="vertical-align: middle;text-align:center"><v-icon small>mdi-drag</v-icon></td>
                      <td class="text-left tbline" style="font-size:12px;vertical-align: middle;" >
                        <div style="width:200px;max-width:200px;overflow-wrap: break-word;" @click="editeffect(element)">{{element.aniname}}</div>
                      </td>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-icon @click="effectplay(element)" color="black" icon="mdi-play" size="x-small"></v-icon>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-icon @click="deleffect(element)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                    </v-col>
                </v-row>
              </template>
            </draggable>    
        </v-row>

    </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import Animation_Model from '@/nextts/model/Animation_Model'
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import draggable from 'vuedraggable' 
import AniProperty_Model from '@/nextts/model/AniProperty_Model';

export default {
  components:{
    draggable
  },


  //프로퍼티 선언
  props:{
    aniplaymodel:{
        type:AnimationPlay_Model,
        default(){
            return reactive(new AnimationPlay_Model());
        }
    },
    categoryList:{
        type:Array,
        default(){
            return [];
        }
    }
  },


  mounted(){
    
  },


  data(){
    return{
        aniplay_model: this.aniplaymodel,

        effect_dialog:false,
        animodel: reactive(new Animation_Model()),

        easelist:[
                {name:"none",   value:"none"}
            ,   {name:"power1", value:"power1"}
            ,   {name:"power2", value:"power2"}
            ,   {name:"power3", value:"power3"}
            ,   {name:"power4", value:"power4"}
            ,   {name:"back",   value:"back"}
            ,   {name:"elastic",value:"elastic"}
            ,   {name:"bounce", value:"bounce"}
            ,   {name:"rough",  value:"rough"}
            ,   {name:"slow",   value:"slow"}
            ,   {name:"steps",  value:"steps"}
            ,   {name:"circ",   value:"circ"}
            ,   {name:"expo",   value:"expo"}
            ,   {name:"sine",   value:"sine"}
        ],

        easetype:[
                {name:"in",     value:"in"}
            ,   {name:"out",    value:"out"}
            ,   {name:"inOut",  value:"inOut"}
        ],

        proptype:[
                {name:"string", value:"string"}
            ,   {name:"number", value:"number"}
            ,   {name:"color",  value:"color"}
        ]

        
    }
  },


  methods:{

    changerepeat(){
        this.aniplay_model.repeat = Number(this.aniplay_model.repeat);
    },

    changedelay(){
        this.aniplay_model.repeatDelay = Number(this.aniplay_model.repeatDelay);
    },

    addprop(){
        var apm = new AniProperty_Model();
        this.animodel.proplist.push(apm);
    },

    save_effect(){
       if(!this.aniplay_model.anilist.includes(this.animodel)){
            this.aniplay_model.anilist.push(this.animodel);
       }
       this.effect_dialog = false;
    },

    new_effect(){
        this.animodel = reactive(new Animation_Model());
        this.effect_dialog = true;
    },
    
    editeffect(am){
        this.animodel = am;
        this.effect_dialog = true;
    },

    //이펙트 재생
    effectplay(am){
        this.$emit('aniplay', am);
    },

    //이펙트 삭제
    deleffect(am){
        const idx = this.aniplay_model.anilist.indexOf(am);
        this.aniplay_model.anilist.splice(idx, 1);
    },    

    changesase(item){
        console.log("changesase " + item);
    },

    anilistplay(){
        this.$emit('anilistplay', this.aniplay_model);
    },

    aniliststop(){
        this.$emit('aniliststop');
    },

    selectani(am){
        this.animodel = am;
        console.log("select ani 2: " + this.animodel.ease);
    },

    async updateaniplay(){

        if(this.aniplay_model.aniplayname.length < 1){
            this.$alert.fire("Animation Name을 입력하세요");
            return;
        }
    
        var temp_obj  = JSON.parse(JSON.stringify(this.aniplay_model));

        if(this.aniplay_model.ani_idx == -1){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //신규
            this.$http.post(this.$host+'/AnimationInsert',{
                ANI_NAME:escape(this.aniplay_model.aniplayname),
                ANI_JSON:escape(JSON.stringify(temp_obj))
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                    this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('추가에 실패했습니다.');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("insert error : " + error);
            });

        }else{
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //업데이트
            this.$http.post(this.$host+'/AnimationUpdate',{
                ANI_NAME:escape(this.aniplay_model.aniplayname),
                ANI_JSON:escape(JSON.stringify(temp_obj)),
                ANI_IDX:this.aniplay_model.ani_idx
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                    this.$alert.fire('수정이 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('수정에 실패했습니다.');
                }

            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("update error : " + error);
            });
        }
        
    },


  },



}
</script>