import { createWebHistory, createRouter } from "vue-router";

//기본화면
import Welcome from '@/pages/Welcome.vue';
import NotFound from '@/pages/NotFound.vue';
import Join from '@/pages/Join.vue';
import ServiceAgree from '@/pages/ServiceAgree.vue';
import Login from '@/pages/Login.vue';
import Price from '@/pages/Price.vue';
import Contact from '@/pages/Contact.vue';


//관리자 화면
import NoticeList from '@/pages/NoticeList.vue'
import NoticeDetail from '@/pages/NoticeDetail.vue'
import ManualList from '@/pages/ManualList.vue'
import ManualDetail from '@/pages/ManualDetail.vue'
import AccntList from '@/pages/AccntList.vue'
import SubAccntList from '@/pages/SubAccntList.vue'
import SubLogin from '@/pages/SubLogin.vue'
import EditContent from '@/pages/EditContent.vue'
import ContentList from '@/pages/ContentList.vue'
import ContentDeleteList from '@/pages/ContentDeleteList.vue'
import ContentTypeList from '@/pages/ContentTypeList.vue'
import PopupList from '@/pages/PopupList.vue'

import AssetList from '@/pages/AssetList.vue'
import EditAni from '@/pages/EditAni.vue'

import ResImgList from '@/pages/ResImgList.vue'
import ResMaskList from '@/pages/ResMaskList.vue'
import ResMvList from '@/pages/ResMvList.vue'
import FontList from '@/pages/FontList.vue'
import SubscriptList from '@/pages/SubscriptList.vue'
import SetPageList from '@/pages/SetPageList.vue'

//고객 화면
import MyPage from '@/pages/MyPage.vue'
import MyDevice from '@/pages/MyDevice.vue'
import MyContent from '@/pages/MyContent.vue'
import MyPayment from '@/pages/MyPayment.vue'
import MyContentDelete from '@/pages/MyContentDelete.vue'
import MyContentGroup from '@/pages/MyContentGroup.vue'
import MyContentGroupDetail from '@/pages/MyContentGroupDetail.vue'
import MySchedule from '@/pages/MySchedule.vue'
import MyScheduleDetail from '@/pages/MyScheduleDetail.vue'
import MyResource from '@/pages/MyResource.vue'

import SelectContentType from '@/pages/SelectContentType.vue'
import SelectContent from '@/pages/SelectContent.vue'
import SelectContentCompany from '@/pages/SelectContentCompany.vue'

import UserPageDetail from '@/pages/UserPageDetail.vue'
import UserResourceDetail from '@/pages/UserResourceDetail.vue'


import Player from '@/pages/Player.vue'

//테스트
import HelloWorld from '@/components/HelloWorld.vue'
import Fablic from '@/components/Fablic.vue'
import GroupTest from '@/components/GroupTest.vue'
import SaveLoad from '@/components/SaveLoad.vue'
import Test from '@/components/Test.vue'
import DragDrop from '@/components/DragDrop.vue'
import EditWindowTest from '@/components/EditWindowTest.vue'
import EditViewer from '@/components/EditViewer.vue'
import EditWindowFixed from '@/components/EditWindowFixed.vue'
import EditWindowScroll from '@/components/EditWindowScroll.vue'
import EditWindowFab from '@/components/EditWindowFab.vue'
import EditContentFab from '@/pages/EditContentFab.vue'
import ContentPlay from '@/components/ContentPlay.vue'



const routes = [
      {path : "/", component:Welcome }
    , {path : "/Welcome", component:Welcome }
    , {path : "/Join", component:Join }
    , {path : "/Login", component:Login }
    , {path : "/ServiceAgree", component:ServiceAgree }
    , {path : "/Price", component:Price }
    , {path : "/Contact", component:Contact }
    
    , {path : "/NoticeList",    component:NoticeList}
    , {path : "/NoticeDetail",  component:NoticeDetail}
    , {path : "/NoticeDetail/:code",  component:NoticeDetail}

    , {path : "/ManualList",    component:ManualList}
    , {path : "/ManualDetail",  component:ManualDetail}
    , {path : "/ManualDetail/:code",  component:ManualDetail}

    , {path : "/AccntList",     component:AccntList}
    , {path : "/SubAccntList",  component:SubAccntList}
    , {path : "/SubLogin",      component:SubLogin}
    , {path : "/EditContent",   component:EditContent}
    , {path : "/EditContent/:type/:idx",   component:EditContent}

    
    

    , {path : "/ContentList",   component:ContentList}
    , {path : "/ContentDeleteList",   component:ContentDeleteList}
    , {path : "/ContentTypeList",   component:ContentTypeList}
    , {path : "/PopupList",     component:PopupList}
    
    , {path : "/AssetList",     component:AssetList}
    
    , {path : "/EditAni",       component:EditAni}
    , {path : "/ResImgList",    component:ResImgList}
    , {path : "/ResMaskList",   component:ResMaskList}
    , {path : "/ResMvList",     component:ResMvList}
    , {path : "/FontList",      component:FontList}
    , {path : "/SubscriptList", component:SubscriptList}
    , {path : "/SetPageList",   component:SetPageList}
    
    
    , {path : "/MyPage",        component:MyPage}
    , {path : "/MyDevice",      component:MyDevice}
    
    , {path : "/MyContent",      component:MyContent}
    , {path : "/MyContentDelete",      component:MyContentDelete}
    , {path : "/MyContentGroup", component:MyContentGroup}
    , {path : "/MyContentGroupDetail", component:MyContentGroupDetail}
    , {path : "/MySchedule",            component:MySchedule}
    , {path : "/MyScheduleDetail",      component:MyScheduleDetail}
    , {path : "/MyResource",    component:MyResource}
    , {path : "/MyPayment",    component:MyPayment}
    

    , {path : "/SelectContentType",     component:SelectContentType}
    , {path : "/SelectContent",         component:SelectContent}
    , {path : "/SelectContentCompany",  component:SelectContentCompany}

    , {path : "/UserPageDetail",  component:UserPageDetail}
    , {path : "/UserResourceDetail",  component:UserResourceDetail}
    

    , {path : "/Player",            component:Player}
    , {path : "/HelloWorld",        component:HelloWorld}
    , {path : "/Fablic",            component:Fablic}
    , {path : "/GroupTest",         component:GroupTest}
    , {path : "/SaveLoad",          component:SaveLoad}
    , {path : "/Test",              component:Test}
    , {path : "/DragDrop",          component:DragDrop}
    , {path : "/EditWindowTest",    component:EditWindowTest}
    , {path : "/EditWindowFab",    component:EditWindowFab}
    , {path : "/EditContentFab",    component:EditContentFab}
    , {path : "/EditViewer",        component:EditViewer}
    , {path : "/EditWindowFixed",   component:EditWindowFixed}
    , {path : "/EditWindowScroll",  component:EditWindowScroll}

    , {path : "/ContentPlay",       component:ContentPlay}
    

    , {path : "/:pathMatch(.*)*", redirect:"/404"}
    , {path : "/404",           component:NotFound}    

]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;