<template>

  <v-container fluid>
    
    <dialogcontent ref="dialogcontent" @callback="selectcontent"/>
    <dialogmycontent ref="dialogmycontent" @callback="selectmycontent"/>

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined label="기기명" hide-details variant="outlined" density="compact"
                  v-model="editedItem.DEVICE_NAME"  ></v-text-field>
                </v-col>
              </v-row>

              
              <v-row>
                <v-col cols="12">
                  <v-select v-model="editedItem.PLAY_TYPE" :items="PLAYTYPELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" 
                    @update:modelValue="changeplaytype"
                    hide-details elevation="0"  
                    label="플레이 타입" />
                </v-col>
              </v-row>

              <v-row v-if="editedItem.PLAY_TYPE == 0">
                <v-col cols="8">
                  <label>{{ editedItem.MCON_NAME }}</label>
                </v-col>
                <v-col cols="4">
                  <v-btn @click="showmypopup">컨텐츠선택</v-btn>
                </v-col>
              </v-row>
              
              <v-row v-if="editedItem.PLAY_TYPE == 1">
                <v-col cols="12">
                  <!-- 
                  <v-select v-model="editedItem.MCON_IDX" :items="contentList" variant="outlined" density="compact"
                    item-title="MCON_NAME" item-value="MCON_IDX" 
                    hide-details elevation="0"  
                    label="컨텐츠" v-if="editedItem.PLAY_TYPE == 0"
                    />
                  -->
                  <v-select v-model="editedItem.MCGROUP_IDX" :items="contentGgroupList" variant="outlined" density="compact"
                    item-title="MCGROUP_NAME" item-value="MCGROUP_IDX" 
                    hide-details elevation="0"  
                    label="컨텐츠 그룹" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-select v-model="editedItem.MSCHD_IDX" :items="scheduleList" variant="outlined" density="compact"
                    item-title="MSCHD_NAME" item-value="MSCHD_IDX" 
                    hide-details elevation="0"  
                    label="스케줄" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <label>{{ editedItem.POPUP_NAME }}</label>
                </v-col>
                <v-col cols="4">
                  <v-btn @click="showpopup">팝업선택</v-btn>
                </v-col>
              </v-row>
              

              <v-row>
                <v-col cols="12">
                  <v-select v-model="editedItem.STATE" :items="STATELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" 
                    hide-details elevation="0"  
                    label="상태" />
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>


    <!-- 계정 팝업 -->
    <v-row>
      <v-dialog style="z-index:1" scrollable overlay-color="#00000040" persistent v-model="dialog_acnt" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">계정 설정</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div>BARO 플레이어에 로그인하기 위한 계정 정보를 설정하세요.</div>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined label="계정" hide-details variant="outlined" density="compact"
                  v-model="editedItem.ACNTID"  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn @click="check_duple">중복체크</v-btn>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12">
                  <v-text-field outlined label="패스워드" hide-details variant="outlined" density="compact"
                  v-model="editedItem.PW"  ></v-text-field>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save_acnt">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 리모트 컨트롤 팝업 -->
    <v-row>
      <v-dialog style="z-index:100" scrollable overlay-color="#00000040" persistent v-model="remote_dialog" max-width="500px">
        <v-card class="pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ editedItem.DEVICE_NAME }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row justify="center">
                <v-col cols="12">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','CONTENT_UPDATE', '')">리로드</v-btn>
                </v-col>

                <v-col cols="12">
                  <v-textarea outlined label="메시지" hide-details variant="outlined" density="compact" 
                    v-model="popupmsg"
                    />
                </v-col>

                <v-col cols="12" class="ma-0">
                  <v-checkbox v-model="istts" label="TTS 사용" density="compact" hide-details/>
                </v-col>
                <v-col cols="12">
                  <v-btn class="remote_btn" large @click="sendremotepopup(popupmsg)">팝업 메시지</v-btn>
                </v-col>

                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','SCHEDULE_CHECK', '')">스케줄체크</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','SCHEDULE_UPDATE', '')">스케줄갱신</v-btn>
                </v-col>

                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','PREV_PAGE', '')">이전</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','NEXT_PAGE', '')">다음</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','REBOOT', '')">재부팅</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="remote_btn" large @click="remotecontrol('NONE','SHUTDOWN', '')">전원끄기</v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="6">
                <v-btn class="dialog_cancel_btn" rounded large  @click="close">닫기</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    


    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="12" md="3" v-if="grade > 5">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>
      
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>

      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="reloaduserinfo">RELOAD</v-btn>
      </v-col>

    </v-row>


  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.DEVICE_NAME>
          <a :class="sort_name=='DEVICE_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('DEVICE_NAME')">기기명</a>
        </template>
        <template v-slot:header.MSCHD_NAME>
          <a :class="sort_name=='MSCHD_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('MSCHD_NAME')">스케줄</a>
        </template>
        <template v-slot:header.STATE>
          <a :class="sort_name=='STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE')">상태</a>
        </template>
        <template v-slot:header.UP_DATE>
          <a :class="sort_name=='UP_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('UP_DATE')">수정일</a>
        </template>

        <template v-slot:item.STATE="{ item }">  
            <label>{{ item.STATE == 0 ? '사용안함' : '사용' }}</label>
        </template>     
        <template v-slot:item.POPUP_NAME="{ item }">  
          <label>{{ item.POPUP_NAME }}</label>
        </template>     
        <template v-slot:item.REMOTECONTROL="{ item }">  
          <v-icon small @click="remote_item(item)">mdi-remote-tv</v-icon>
        </template>     

        <template v-slot:item.UP_DATE_FORMAT="{ item }">  
          {{ this.$momenttz.utc(item.UP_DATE_FORMAT).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>

        <template v-slot:item.PLAY="{ item }">  
          {{ item.PLAY }}
        </template>     

        <template v-slot:item.ACNTID="{ item }">  
          <template v-if="item.ACNTID == null || item.ACNTID == undefined || item.ACNTID.length == 0">
            <v-icon small @click="edit_acnt(item)">mdi-pencil</v-icon>
          </template>
          <template v-else>
            <a style="color:blue;cursor:pointer" @click="edit_acnt(item)">{{ item.ACNTID }}</a>
          </template>
          
        </template>     

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { ref } from '@vue/reactivity';
import { doc, onSnapshot, getDoc, setDoc, collection, getDocs } from "firebase/firestore";


export default {
    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시
      
      remote_dialog:false,
      popupmsg:'',

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog_acnt:false,
      defaultItem: {
          LOCALID:'',
          DEVICE_NAME:'',
          PLAY_TYPE:0,
          MCON_IDX:null,
          MCGROUP_IDX:null,
          MSCHD_IDX:null,
          STATE:1,
          PLAY:0,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '기기명', key: 'DEVICE_NAME', sortable: false, align:'center'},
        {title: '스케줄', key: 'MSCHD_NAME', sortable: false, align:'center'},
        {title: '상태', key: 'STATE', sortable: false, align:'center'},
        {title: '수정일', key: 'UP_DATE_FORMAT', sortable: false, align:'center'},
        {title: '팝업', key: 'POPUP_NAME', sortable: false, align:'center'},
        {title: '제어', key: 'REMOTECONTROL', sortable: false, align:'center'},
        {title: '재생', key: 'PLAY', sortable: false, align:'center'},
        {title: '계정', key: 'ACNTID', sortable: false, align:'center'},
        {title: 'PW', key: 'PW', sortable: false, align:'center'},
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],
      itemList: [],

      sort_name: 'DEVICE_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      STATELIST: [
          {name:'사용중지',   value:0}
        , {name:'사용',       value:1}
      ],

      PLAYTYPELIST: [
          {name:'단일컨텐츠', value:0}
        , {name:'컨텐츠그룹', value:1}
      ],

      contentList: [],
      contentGgroupList: [],
      scheduleList: [],

         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      
      grade:0,
      
      istts:0,

      engrules:[v => !v || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) || '한글을 사용할 수 없습니다.'],

      check_acntid: null,

      //test
      unConnected:null,

    }),

    setup(){
        var paging        = ref(null);
        var searchbar     = ref(null);
        var editor        = ref(null);
        var dialogcontent = ref(null);
        var dialogmycontent = ref(null);
        return {paging, searchbar, editor, dialogcontent, dialogmycontent};
    },

    beforeMount(){

      //리스너 해제
      if(this.unConnected){
        this.unConnected();
      }

    },

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      sendremotepopup(msg){
        
        const test = this.convertTextNumbersToKorean(msg);
        console.log(test);

        
        if(this.istts){
          this.remotecontrol('POPUPTTS','NONE', msg);
        }else{
          this.remotecontrol('POPUPMSG','NONE', msg);
        }
        

      },



      // 메시지에서 숫자를 찾아 한글로 변환
      convertTextNumbersToKorean(text) {
        return text.replace(/\b\d+\b/g, (num) => this.numberToKorean(parseInt(num, 10)));
      },

      // 숫자를 한글로 변환하는 함수
      numberToKorean(number) {
        
        console.log("num : " + number);
        const koreanNumber = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
        const tenUnit = ['', '십', '백', '천'];
        const tenThousandUnit = ['조', '억', '만', ''];
        const unit = 10000;

        let answer = '';

        while (number > 0) {
          const mod = number % unit;
          const modToArray = mod.toString().split('');
          const length = modToArray.length - 1;

          const modToKorean = modToArray.reduce((acc, value, index) => {
            const valueToNumber = +value;
            if (!valueToNumber) return acc;
            // 단위가 십 이상인 '일'글자는 출력하지 않는다. ex) 일십 -> 십
            const numberToKorean = index < length && valueToNumber === 1 ? '' : koreanNumber[valueToNumber];
            return `${acc}${numberToKorean}${tenUnit[length - index]}`;
          }, '');

          answer = `${modToKorean}${tenThousandUnit.pop()} ${answer}`;
          number = Math.floor(number / unit);
        }

        return answer.replace();       

      },      


      async remotecontrol(showtype, runtype, arg){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if(this.$storage.getStorageSync("LIMIT_POPUP") == 0){
            this.$alert.fire("사용 권한이 없습니다.");
            return;
          }
        }
        
        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.$http.post(this.$host+'/RemoteControl',{
                DEVICE_IDX: this.editedItem.DEVICE_IDX
              , SHOWTYPE: showtype
              , RUNTYPE: runtype
              , ARG: arg
              , LOCALID:this.SEARCH_LOCALID
              , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            console.log("result : " + JSON.stringify(result));
            if(result.data.resultCode == 0){
              this.$alert.fire("리모트 설정이 완료되었습니다.");
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.$alert.fire(result.data.resultMsg);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });        

      },



      remote_reboot(){

        this.$alert.fire({title:"해당 장비를 정말 재부팅하시겠습닌까?", customClass:{container:'frontswal'}, showCancelButton:true})
        .then(async (aresult) => {
          if(aresult.isConfirmed){

            console.log("reboot.......");

          }
        });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/DeviceList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

                //모니터링 권한이 있는 경우만 리스너 등록
                if(this.unConnected){
                  this.unConnected();
                }
                this.readcoldb();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      changeplaytype(){
        //로드
        this.editedItem.MCGROUP_IDX = null;
        this.editedItem.MCON_IDX = null;
      },

      //등록된 내컨텐츠 로드
      async loadmycontent(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.$http.post(this.$host+'/MyContentList',{
              search_keyword: ""
            , sort_name: "MCON_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
            , LOCALID:this.SEARCH_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.contentList.splice(0, this.contentList.length);   //데이터 초기화
              list.forEach(element => {
                this.contentList.push(element); 
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },      

      //컨텐츠 그룹
      async loadContentGroup(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.$http.post(this.$host+'/MyContentGroupList',{
                search_keyword: ''
              , sort_name: "MCGROUP_NAME"
              , sort_type: "DESC"
              , page: 1
              , pagerow: 99999
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.contentGgroupList.splice(0, this.contentGgroupList.length);   //데이터 초기화
                list.forEach(element => {
                    this.contentGgroupList.push(element); 
                });

                console.log("contentGgroupList : " + JSON.stringify(this.contentGgroupList));

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.$emitter.emit('clearsession');
          });

      },      

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);

        //로드
        this.loadmycontent();
        this.loadContentGroup();
        this.loadschedule();
      },      


      async loadschedule(){

        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

        this.$http.post(this.$host+'/MyScheduleList',{
              search_keyword: ""
            , sort_name: "MSCHD_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 9999
            , LOCALID:this.SEARCH_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.scheduleList.splice(0, this.scheduleList.length);
              list.forEach(element => {
                  this.scheduleList.push({ MSCHD_IDX:element.MSCHD_IDX, MSCHD_NAME:element.MSCHD_NAME }); 
              });

              console.log("scheduleList : " + JSON.stringify(this.scheduleList));

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false;
        this.dialog_pw  = false;
        this.dialog_acnt = false;
        this.remote_dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //계정 중복확인
      async check_duple(){

        if(this.editedItem.ACNTID  == undefined || this.editedItem.ACNTID.length < 1){
          this.$alert.fire("계정을 입력하세요");
          return;
        } 

        if(this.editedItem.ACNTID.length < 4 || this.editedItem.ACNTID.length > 20){
          this.$alert.fire("계정은 4자 이상 20자 이하로 입력하세요");
          return;
        } 

        const check = /[a-z0-9-_]/;
        if(!check.test(this.editedItem.ACNTID)){
          this.$alert.fire("계정은 영어 소문자, 숫자, -,_만 사용할 수 있습니다.");
          return;
        }

        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
        this.$http.post(this.$host+'/DeviceAcntCheck',{
          ACNTID: this.editedItem.ACNTID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            this.$alert.fire("사용 가능한 계정입니다.");
            this.check_acntid = this.editedItem.ACNTID;

          }else if(result.data.resultCode == 1){
            this.$alert.fire("사용이 불가능한 계정입니다.");
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.$alert.fire('수정이 실패했습니다.');
        });


      },

      //계정 저장
      async save_acnt () {


        if(this.editedItem.ACNTID  == undefined || this.editedItem.ACNTID.length < 1){
          this.$alert.fire("계정을 입력하세요");
          return;
        } 

        if(this.editedItem.ACNTID.length < 4 || this.editedItem.ACNTID.length > 20){
          this.$alert.fire("계정은 4자 이상 20자 이하로 입력하세요");
          return;
        } 

        const check = /[a-z0-9-_]/;
        if(!check.test(this.editedItem.ACNTID)){
          this.$alert.fire("계정은 영어 소문자, 숫자, -,_만 사용할 수 있습니다.");
          return;
        }

        if(this.check_acntid == null || this.check_acntid == undefined || this.check_acntid.length == 0){
          this.$alert.fire("중복체크를 해주세요.");
          return;
        }

        if(this.check_acntid != this.editedItem.ACNTID){
          this.$alert.fire("중복체크를 해주세요.");
          return;
        }

        if(this.editedItem.PW  == undefined || this.editedItem.PW.length < 1){
          this.$alert.fire("패스워드를 입력하세요");
          return;
        } 
        if(this.editedItem.PW.length < 4 || this.editedItem.PW.length > 20){
          this.$alert.fire("패스워드는 4자 이상 20자 이하로 입력하세요");
          return;
        } 

        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
        this.$http.post(this.$host+'/DeviceAcntUpdate',{
              ACNTID: this.editedItem.ACNTID
            , PW: this.editedItem.PW
            , DEVICE_IDX: this.editedItem.DEVICE_IDX
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              this.loadpage(this.page);
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.$alert.fire('수정이 실패했습니다.');
        });

        this.close();

      },

      //추가 저장
      async save () {

          if(this.editedItem.DEVICE_NAME  == undefined || this.editedItem.DEVICE_NAME.length < 1){
            this.$alert.fire("기기명을 입력하세요");
            return;
          } 

          if(this.grade > 90){
            if(this.SEARCH_LOCALID == null || this.SEARCH_LOCALID == undefined){
              this.$alert.fire("계정을 선택하세요");
              return;
            }
          }        

        if (this.editedIndex > -1) {
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/DeviceUpdate',{
                DEVICE_NAME: escape(this.editedItem.DEVICE_NAME)
              , STATE: this.editedItem.STATE
              , DEVICE_IDX: this.editedItem.DEVICE_IDX
              , MCON_IDX: this.editedItem.MCON_IDX
              , MCGROUP_IDX: this.editedItem.MCGROUP_IDX
              , MSCHD_IDX: this.editedItem.MSCHD_IDX
              , POPUP_IDX: this.editedItem.POPUP_IDX
              , PLAY_TYPE: this.editedItem.PLAY_TYPE
              , LOCALID:this.SEARCH_LOCALID
              , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              const p = this.page;
              this.page = 0;
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(p);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });


        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //신규
          this.loading = true;
          this.$http.post(this.$host+'/DeviceInsert',{
                LOCALID: this.SEARCH_LOCALID
              , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
              , MCON_IDX: this.editedItem.MCON_IDX
              , MCGROUP_IDX: this.editedItem.MCGROUP_IDX
              , MSCHD_IDX: this.editedItem.MSCHD_IDX
              , PLAY_TYPE: this.editedItem.PLAY_TYPE
              , POPUP_IDX: this.editedItem.POPUP_IDX
              , DEVICE_NAME: escape(this.editedItem.DEVICE_NAME)
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("추가가 완료되었습니다.").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('추가 실패');
          });


        }

        this.close();
      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/MyDevice", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      readcoldb(){

        //컬렉션 리스너 등록
        const connectedRef = doc(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "login", "logintime");
        this.unConnected = onSnapshot(connectedRef, (doc)=>{
          //this.checkPlay(change.doc);
          const data = doc.data();
          if(data.devicelist){
            console.log("devicelist : " + data.devicelist.length);
            if(data.devicelist.length > 0){
              data.devicelist.forEach(element => {
                this.checkPlay(element.DEVICE_IDX);
              });
            }else{
              this.itemList.forEach(item => {
                item.PLAY = 0;
              });
            }
          }
        });
      },

      //플레이 중인지 체크
      checkPlay(didx){
        const count = this.itemList.filter(item => item.DEVICE_IDX == didx).length;
        console.log("count : " + count + " / " + didx);
        // itemList의 각 객체에 count 필드를 추가
        this.itemList = this.itemList.map(item => {
          if (item.DEVICE_IDX == didx) {
            return {
              ...item,
              PLAY: count
            };
          }
          return item;
        });
      },

      reloaduserinfo(){

        //현재의 요금제 상태를 갱신한다. => 모든 디바이스, 관리자가 콜백됨.
        //변동사항이 없으면 콜백 안됨.
        this.$alert.fire({showCancelButton: true, title:'계정 정보를 최신으로 리로드합니다. 모든 장비가 변경된 최신 정보를 확인합니다.'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){

            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.$http.post(this.$host+'/reloadUserInfo',{
              LOCALID:this.SEARCH_LOCALID
            },{headers: { firetoken:token}})
            .then((result)=>{
              if(result.data.resultCode == 0){
                this.$alert.fire('처리가 완료되었습니다.');
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('처리에 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('처리에 실패했습니다.');
            });      
          }
        });        

      },
      
      //신규 추가
      newinsert(){


        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.PLAY_TYPE = 0;
        this.dialog = true;
      },

      //아이템 수정
      remote_item(item){
        this.editedItem = Object.assign({}, item);
        this.remote_dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item);
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },


      //계정 수정
      edit_acnt(item){
        this.editedIndex = this.itemList.indexOf(item);
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.check_acntid = this.editedItem.ACNTID;                  
        this.dialog_acnt = true;
      },      

      //아이템 삭제
      async delete_item(item){


        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/DeviceDelete',{
                DEVICE_IDX:item.DEVICE_IDX
              , LOCALID:this.SEARCH_LOCALID
              , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },

      showpopup(){

        if(this.$storage.getStorageSync("LIMIT_POPUP") == 0){
          this.$alert.fire('사용 권한이 없습니다.');
          return;
        }

        this.dialogcontent.loadcontent(2);
      },

      showmypopup(){
        this.dialogmycontent.loadcontent(this.SEARCH_LOCALID);
      },

      selectcontent(selectvalue){
        if(selectvalue != null){
          console.log("selectvalue : " + JSON.stringify(selectvalue));
          this.editedItem.POPUP_IDX  = selectvalue.idx;
          this.editedItem.POPUP_NAME = selectvalue.name;
        }else{
          this.editedItem.POPUP_IDX  = null;
          this.editedItem.POPUP_NAME = null;
        }
      },

      selectmycontent(selectvalue){
        if(selectvalue != null){
          console.log("selectmycontent : " + JSON.stringify(selectvalue));
          this.editedItem.MCON_IDX  = selectvalue.idx;
          this.editedItem.MCON_NAME = selectvalue.name;
        }else{
          this.editedItem.MCON_IDX  = null;
          this.editedItem.MCON_NAME = null;
        }
      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.frontswal{
  z-index: 9999 !important;
}
</style>