
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import AniUtil from "@/nextts/play/AniUtil";
import gsap from "gsap";
import Content_Model from '@/nextts/model/Content_Model';
import { emitter } from '@/emitter';

//기본 데이터 속성
class Play_ImageSwitcher extends HTMLElement implements Http_Interface, Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;

    switch_timeline:null|GSAPTimeline = null;
    switch_timer:NodeJS.Timeout|null = null;
    
    flag = true;
    text1:HTMLElement|null = null;
    text2:HTMLElement|null = null;
    
    private updateTextHandler:any;

    //이미지 마스크용
    base64String?:null|string;

    constructor(m:Component_Model){
        super();
        this.model = m;
        if(this.model != null){
            this.model.imgswitcher_pos = 0;
        }
        this.style.position = "absolute";
        this.style.overflow = "hidden";
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Text init");

        //api 사용이면 api값을 가져와서 미리 셋팅한다.
        if(this.model?.api_use){
            console.log("apiuse ImgSwitcher");

            this.updateTextHandler = this.updatetext_handler.bind(this);
            emitter.on('updatetext', this.updateTextHandler);

            //캐싱 일부러 안함.

        }

        //컴포넌트 시작
        this.playAni();

    }

    //json값에서 path에 해당하는 값을 꺼냄.
    getValueByPath<T>(json: Record<string, any>, path: string): T | undefined {
        const parts = path.match(/\[([^\]]+)\]/g) || [];
        return parts.reduce((acc: any, part: string) => {
            // 대괄호를 제거하고 실제 키값만 추출
            const key = part.replace(/\[|\]/g, '');
            // 숫자로 변환할 수 있는 키는 배열의 인덱스로, 그렇지 않은 키는 객체의 키로 처리
            return acc && (acc.hasOwnProperty(key) ? acc[key] : acc[Number(key)]);
        }, json) as T;
    }

    exit(): void {
        
        //효과음 초기화 (일단 패스)
        console.log("exti switcher...");
        if(this.model != null && this.model.api_use){
            emitter.off('updatetext', this.updateTextHandler);
        }

        //스위처 타이머 초기화
        if(this.switch_timer != null){
            clearInterval(this.switch_timer);
        }

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        //스위처애니 초기화
        if(this.switch_timeline != null){
            this.switch_timeline.clear();
            this.switch_timeline = null;
        }

        //컴포넌트 클리어
        while(this.firstChild){
            this.removeChild(this.firstChild);
        }

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
            if(this.model.api_use){
                //api 사용이면 스위처 내용 초기화
                //this.model.imgswitcher_list.splice(0, this.model.imgswitcher_list.length);
            }
        }

    }


    async updatetext_handler(){

        if(this.model == null || this.model == undefined){
            return;
        }
        
        if(Content_Model.apiData.has(this.model.apiname)){
            const resultdata = Content_Model.apiData.get(this.model.apiname);
            //캐싱처리 먼저
            for (const imgpath of this.model.imgswitcher_list) {
                const dataary = this.getValueByPath(resultdata, imgpath as string) as any;
                //http url이면 캐싱처리 해야한다.
                if(dataary.toLowerCase().startsWith("http")){
                    await this.loadImageAsync(dataary);
                }
            }
        }

    }    

    loadImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
    }        

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            console.log("play ani...1");
            setTimeout(() => {
               this.drawView();
               //애니가 있으면 첫번째 텍스트를 미리 그려놔야한다. 그래야 애니매이션이 되는 텍스트가 보인다.
               this.flag = false;
               this.text2!.style.backgroundImage  = this.getPositionData(this.model!.imgswitcher_pos) as string;
               this.callbackSwitcher();
               this.runAni();
            }, this.model.aniplaymodel.anilist[0].delay * 1000);
        }else{
            console.log("play ani...2");
            //애니 없음
            this.drawView();
            
            //애니가 없으면 바로 스위처 실행
            this.startSwitcher();
        }

    }

    //애니매이션 실행
    runAni(){
        //console.log("Play Text run ani");
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);

        let move_rotatex = 0;
        let move_rotatey = 0;
        let move_skewx   = 0;
        let move_skewy   = 0;

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            move_rotatex += Number(ani.rotatex);
            move_rotatey += Number(ani.rotatey);
            move_skewx += Number(ani.skewx);
            move_skewy += Number(ani.skewy);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                                                            , scaleX:ani.scalex, scaleY:ani.scaley
                                                            , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                                                            , skewX:move_skewx, skewY:move_skewy        
                                                            , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
        });        

        this.timeline.eventCallback("onComplete", ()=>{
            //애니가 끝나면 콜백 실행
            this.startSwitcher();
        });       
    }


    async drawView(){

        //console.log("================ Play_Text drawView ===================");
        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.width  = this.model.width+"px";
        this.style.height = this.model.height+"px";
        this.style.opacity          = String(this.model.opacity);
        
        this.text1 = this.createImgElement();
        this.text2 = this.createImgElement();
        this.appendChild(this.text1!);
        this.appendChild(this.text2!);

        //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
        if(this.model.mask_use){
            this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
            this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
            if(this.model.mask_repeat){
                this.style.webkitMaskRepeat = "repeat";    
            }else{
                this.style.webkitMaskRepeat = "no-repeat";    
            }
            if(this.model.mask_image.length > 0){
                if(this.model.mask_image.toLowerCase().startsWith("http")){
                    //인터넷에 업로드된 이미지면
                    try {
                        if(this.base64String == null){
                            this.base64String = await this.convertImageToBase64(this.model.mask_image);
                        }
                        this.style.webkitMaskImage = `url(${this.base64String})`;    
                    } catch (error) {
                        console.error("Error during image conversion to Base64:", error);
                    }                        
                }else{
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }

            }else{
                this.style.webkitMaskImage = "";    
            }
        }else{
            this.style.webkitMaskImage = "";
            this.style.webkitMaskPosition = "";
            this.style.webkitMaskSize = "";
        }

        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }

    //스위처 시작
    startSwitcher(){

        if(this.model == null || this.model == undefined){
            return null;
        }

        //애니매이션 셋팅
        if(this.switch_timeline != null){
            this.switch_timeline.clear();
            this.switch_timeline = null;
        }
        this.switch_timeline = gsap.timeline({repeat: 0});
        
        if(isNaN(this.model.imgswitcher_time)){
            this.model.imgswitcher_time = 3;
        }

        this.nextSwitcher();
        this.switch_timer = setInterval(()=>{
            console.log("setInterval nextSwitcher.....");
            this.nextSwitcher();
        }, this.model.imgswitcher_time * 1000);        

    }

    nextSwitcher(){
        
        console.log("=========== nextSwitcher : " + this.model!.imgswitcher_pos + " / " + this.flag);
        
        if(this.flag){
            this.text2!.style.zIndex = String(1);
            this.text1!.style.zIndex = String(2);

            this.text1!.style.backgroundImage  = this.getPositionData(this.model!.imgswitcher_pos) as string;
            //애니실행
            AniUtil.setSwitcherAni(this.switch_timeline!, this.model!.imgswitcher_ani, this.text1!, this.text2!);
    
        }else{
            this.text1!.style.zIndex = String(1);
            this.text2!.style.zIndex = String(2);
            
            this.text2!.style.backgroundImage  = this.getPositionData(this.model!.imgswitcher_pos) as string;

            //애니실행
            AniUtil.setSwitcherAni(this.switch_timeline!, this.model!.imgswitcher_ani, this.text2!, this.text1!);
        }

        this.switch_timeline!.eventCallback("onComplete", ()=>{
            this.callbackSwitcher();
        });       

    }

    callbackSwitcher(){
        //애니가 끝나면 콜백 실행
        if(this.model!.imgswitcher_pos+1 < this.model!.imgswitcher_list.length){
            this.model!.imgswitcher_pos += 1;
        }else{
            this.model!.imgswitcher_pos = 0;
        }
        if(this.flag){
            this.flag = false;
        }else{
            this.flag = true;
        }
    }


    getPositionData(pos:number):any{
        if(this.model?.api_use){
            if(Content_Model.apiData.has(this.model.apiname)){
                const resultdata = Content_Model.apiData.get(this.model.apiname);
                const dataary = this.getValueByPath(resultdata, this.model!.imgswitcher_list[pos] as string) as any;
                if(dataary != null && dataary != undefined && dataary != 'undefined' && dataary.length > 0){
                    return `url('${dataary}')`;
                }else{
                    if(this.model!.imgswitcher_pos+1 < this.model!.imgswitcher_list.length){
                        this.model!.imgswitcher_pos += 1;
                    }else{
                        this.model!.imgswitcher_pos = 0;
                    }
                    return this.getPositionData(this.model!.imgswitcher_pos);
                }
            }

        }else{
            return `url('${this.model!.imgswitcher_list[pos]}')`;
        }
    }
    
    createImgElement():HTMLElement|null{

        if(this.model == null || this.model == undefined){
            return null;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        const texthtml = document.createElement("div");
        texthtml.style.position = "absolute";
        texthtml.style.backgroundColor  = "#00000000";
        texthtml.style.marginLeft   = "0px";
        texthtml.style.marginTop    = "0px";
        texthtml.style.width        = this.model.width+"px";
        texthtml.style.height       = this.model.height+"px";
        texthtml.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
        texthtml.style.backgroundRepeat = "no-repeat";

        //input 텍스트처리 (더블클릭시)
        texthtml.contentEditable = "false";
        texthtml.innerText = "";

        texthtml.style.cssText += "transform: translateX('0px') translateY('0px') rotate("+`${this.model.rotate}deg`+")";
        return texthtml;

    }


    //이미지 마스크 CORS 우회 처리
    loadMaskImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // CORS 정책 준수를 위해 필요
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
            img.src = url;
        });
    }

    convertImageToBase64(url: string): Promise<string> {
        return this.loadMaskImageAsync(url).then((img: HTMLImageElement) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Unable to get canvas context');
            }
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            return canvas.toDataURL('image/png');
        }).catch(error => {
            console.error("Error in converting image to Base64:", error);
            throw error;
        });
    }        

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

        
    }

}

export default Play_ImageSwitcher
customElements.define('play-imageswitcher', Play_ImageSwitcher);