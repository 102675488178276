<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >
    

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040"
      v-model="crop_dialog_land" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>

    <comsubscript ref="comsubscript"/>

    <!-- 요금제 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="pay_dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">요금제 추가</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">요금제</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="payItem.SUB_IDX" :items="SUBLIST" variant="outlined" density="compact"
                    item-title="SUB_NAME" item-value="SUB_IDX" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    /> 
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="pay_dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="savepay">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    


    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">계정</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field disabled
                    v-model="editedItem.ACCNT" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">이름</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.NAME" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <!-- 수정하면 문제됨
              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">소속</label>
                </v-col>
                <v-col cols="9">
                  <dialogcompany :comname="editedItem.PARENT_NAME" 
                    @cancelcallback="companycancel" @callback="companycb"  
                    /> 
                </v-col>
              </v-row>
              -->

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">회원등급</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.GRADE" :items="GRADELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    /> 
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">사용지역</label>
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    clearable
                    :items="timeList"
                    variant="outlined"
                    density="compact" hide-details
                    v-model="editedItem.TIMEZONE"
                    />
                </v-col>
              </v-row>

            <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
              <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">가로 로고</p>
              </v-col>
              <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                <div class="black_line"/>    
              </v-col>
              <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                <v-col align="center" align-self="center" >

                  <template v-if="previewImg1.length > 0">
                    <v-row dense  justify="center">
                      <div style="position:relative">
                        <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                          <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                        </div>
                        <img  width="240" style="hegiht:auto" :src="previewImg1" class="image">
                      </div>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row dense class="image-border2" align="center" justify="center">
                      <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(0)">mdi-image</v-icon>
                    </v-row>
                  </template>
                </v-col>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
              <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">세로 로고</p>
              </v-col>
              <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                <div class="black_line"/>    
              </v-col>
              <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                <v-col align="center" align-self="center" >
                  <template v-if="previewImg2.length > 0">
                    <v-row dense  justify="center">
                        <div style="position:relative">
                          <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                            <v-icon style="width:80px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                            <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                            <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                          </div>
                          <img  width="240" style="hegiht:auto" :src="previewImg2" class="image">
                        </div>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row dense class="image-border2" align="center" justify="center">
                      <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(1)">mdi-image</v-icon>
                    </v-row>
                  </template>
                </v-col>
              </v-col>
            </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">회원상태</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.STATE" :items="STATELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense  align="center" justify="center" v-if="this.$storage.getStorageSync('GRADE') > 90">
                <v-col cols="3">
                  <label>APIKEY</label><label style="color:red">*</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.APIKEY" dense single-line disabled 
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>
              <v-row v-if="editedItem.APIKEY != null && editedItem.APIKEY.length == 0 && this.$storage.getStorageSync('GRADE') > 90" dense align="center" justify="center">
                <v-col cols="3">
                </v-col>
                <v-col cols="9" align="center">
                  <label>API 사용을 원하시면 아래 버튼을 눌러 APIKEY를 생성하세요.</label>
                </v-col>
                <v-col cols="3"></v-col>
                <v-col cols="9" align="center">
                  <v-btn @click="createAPIKey(editedItem.LOCALID)">Create API KEY</v-btn>
                </v-col>
              </v-row>    
              <v-row dense align="center" justify="center" v-if="this.$storage.getStorageSync('GRADE') > 90">
                <v-col cols="3">
                  <label>API 상태</label><label style="color:red">*</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.API_STATE" :items="APISTATELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" dense class="mt-3">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

  <v-row dense>
    <v-col cols="12">
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-col>
  </v-row>
    
  <v-row align="start" dense>
    <v-col > 
        <v-table>
            <thead>
                <tr>
                    <th class="text-left" @click="customsort('ACCNT')">계정</th>
                    <th class="text-left">이름</th>
                    <th class="text-left">소속</th>
                    <th class="text-left">회원등급</th>
                    <th class="text-left">상태</th>
                    <th class="text-left">지역</th>
                    <th class="text-left">요금제</th>
                    <th class="text-left">만료일</th>
                    <th class="text-left">설정</th>
                    <th class="text-left">결제</th>
                    <th class="text-left">전체사용</th>
                    <th class="text-left">링크</th>
                    <th class="text-left">수정</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in itemList" :key="item.name">
                    <td>{{ item.ACCNT }}</td>
                    <td>{{ item.NAME }}</td>
                    <td>{{ item.PARENT_NAME }}</td>
                    <td>{{ item.GRADE == 99 ? '슈퍼관리자' : item.GRADE == 80 ? '컨텐츠관리자' : item.GRADE == 10 ? '기업관리자' : item.GRADE == 5 ? '기업회원' : '일반회원' }}</td>
                    <td>{{ item.STATE == 1 ? '활성' : '탈퇴' }}</td>
                    <td>{{ item.TIMEZONE }}</td>
                    <td><a style="cursor:pointer;color:blue" @click="load_subscript(item)">{{item.SUB_NAME}}</a></td>
                    <td>{{ item.EXFIRE_DATE }}</td>
                    <td><a style="cursor:pointer;color:blue" @click="set_payment(item)">설정</a></td>
                    <td><a style="cursor:pointer;color:blue" @click="load_payment(item)">결제</a></td>
                    <td>{{ item.TOTALSIZE }}</td>
                    <td>
                      <v-icon v-if="item.GRADE >= 10" @click="copysubloginlink(item)">mdi-link</v-icon>
                    </td>
                    <td><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon></td>
                </tr>
            </tbody>
        </v-table>

    </v-col>
  </v-row>


  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
//import 'cropperjs/dist/cropper.css';
//import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리

import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";
import { ref as fref, uploadBytesResumable, uploadBytes, deleteObject } from "firebase/storage";
import { ref } from '@vue/reactivity';
export default {
    components:{
      VueCropper
    },

    setup(){
        var comsubscript = ref(null);
        var cropper_land = ref(null);
        var searchbar = ref(null);
        var paging = ref(null);
        return {comsubscript, cropper_land, searchbar, paging};
    },

    data: () => ({
      progress_dialog:false,
      progress_value:0,

      pay_dialog: false,
      payItem:{},
      SUBLIST: [],

      //파일 업로드
      uploadFile1 : [],
      uploadFile2 : [],
      previewImg1:"",
      previewImg2:"",
      crop_dialog_land: false,
      photo_index:0,
      imgSrc:'',
      ratio: 16/9,
      
      options: {
        focus:false,
      },      

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1920,1080],   //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          ACCNT:'',
          REFIMAGE:'',
          GRADE:1,
          STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '계정', key: 'ACCNT', sortable: false, align:'center', class:"cheader_normal"},
        {title: '이름', key: 'NAME', sortable: false, align:'center', class:"cheader_normal"},
        {title: '등급', key: 'GRADE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '상태', key: 'STATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '지역', key: 'TIMEZONE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '요금제', key: 'SUB_NAME', sortable: false, align:'center', class:"cheader_normal" },
        {title: '결제', key: 'PAY_AMOUNT', sortable: false, align:'center', class:"cheader_normal" },
        {title: '사용량', key: 'TOTALSIZE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '링크', key: 'SUBLOGIN', sortable: false, align:'center', class:"cheader_normal" },
        {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],
      timeList: [],

      //검색 파라메터
      sort_name: 'ACCNT',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      //추가 변수들
      dialog_pw : false,
      GRADELIST: [],
      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'회원',       value:1}
      ],

      grade:0,

      APISTATELIST:[
          {name:'사용',    name2:'Use',             value:0}
        , {name:'사용불가', name2:'Unavailable',    value:1}
      ],

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 10){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$storage.getStorageSync("GRADE");

      if(this.grade > 90){
        //슈퍼관리자
        this.GRADELIST.push({name:'일반회원',     value:1});
        this.GRADELIST.push({name:'기업회원',     value:5});
        this.GRADELIST.push({name:'기업관리자',   value:10});
        this.GRADELIST.push({name:'컨텐츠관리자', value:80});
        this.GRADELIST.push({name:'슈퍼관리자',   value:99});
      }else if(this.grade == 10){
        //기업관리자
        this.GRADELIST.push({name:'일반회원',     value:1});
        this.GRADELIST.push({name:'기업회원',     value:5});
        this.GRADELIST.push({name:'기업관리자',   value:10});
      }


      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }
    },

    beforeUnmount(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },    

    methods: {

      load_subscript(item){
        this.comsubscript.loadpage(item.SUB_IDX);
      },

      async set_payment(item){

        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

        //내용 가져오기
        this.$http.post(this.$host+'/SubscriptList',{
            search_keyword: ""
          , sort_name: "REG_DATE"
          , sort_type: "DESC"
          , page: 1
          , pagerow: 9999
        },{headers: { firetoken:token}})
        .then(async (result)=>{
          
          if(result.data.resultCode == 0){
            const list = result.data.resultData;
            this.SUBLIST.splice(0, this.SUBLIST.length);
            list.forEach(element => 
            {
              this.SUBLIST.push(element); 
            });

            this.payItem.LOCALID  = item.LOCALID;
            this.payItem.PAY_CODE = "NONE";
            this.payItem.PAY_MID  = "NONE";
            this.payItem.PAY_PG   = "NONE";
            this.payItem.PAY_AMOUNT = 0;
            this.payItem.PAY_METHOD = "NONE";
            this.payItem.PAY_INFO   = "관리자 추가";
            this.payItem.PAY_TITLE  = "관리자 추가";
            this.payItem.PAY_NAME   = "관리자";
            this.payItem.PAY_STATE  = 1;
            this.payItem.SUB_IDX    = null;

            this.pay_dialog = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });
        
      },

      load_payment(item){
        
        this.$storage.setStorageSync("SEARCH_LOCALID",           item.LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    item.PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        item.NAME);
        this.$router.push({path:"/MyPayment", query:{pid:item.PARENT_LOCALID, lid:item.LOCALID}});
      },

      async savepay () {

          if(this.payItem.SUB_IDX == undefined || this.payItem.SUB_IDX == null){
            this.$alert.fire("요금제를 선택하세요");
            return;
          } 

          //토큰값 체크
          let token;
          try{
            token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
          }

          this.$http.post(this.$host+'/PaymentInsert',{
                SUB_IDX: this.payItem.SUB_IDX
              , PAY_CODE: escape(this.payItem.PAY_CODE)
              , PAY_MID: escape(this.payItem.PAY_MID)
              , PAY_PG: escape(this.payItem.PAY_PG)
              , PAY_METHOD: escape(this.payItem.PAY_METHOD)
              , PAY_INFO: escape(this.payItem.PAY_INFO)
              , PAY_TITLE: escape(this.payItem.PAY_TITLE)
              , PAY_NAME: escape(this.payItem.PAY_NAME)
              , PAY_AMOUNT: this.payItem.PAY_AMOUNT
              , PAY_STATE: this.payItem.PAY_STATE
              , LOCALID: this.payItem.LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                this.$alert.fire("수정이 완료되었습니다").then(()=>{
                  this.loadpage(this.page);
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.$alert.fire('수정이 실패했습니다.');
          });

          this.close();
      },            

      async loadtimezone(){

        this.$http.post(this.$host+'/GuestTimezone',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.timeList.splice(0, this.timeList.length);   //데이터 초기화
              list.forEach(element => {
                this.timeList.push(element.CITY); 
              });
          }else{
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },      

      async copysubloginlink(item){

        console.log(window.location.origin);
        await navigator.clipboard.writeText(window.location.origin+'/SubLogin?pidx='+item.LOCALID);

      },


      //업체선택 취소
      companycancel(){

      },

      //업체선택 콜백
      companycb(val){
        if(val.LOCALID.length > 0){
          this.editedItem.PARENT_NAME = val.NAME;
          this.editedItem.PARENT_LOCALID = val.LOCALID;
        }
      },          

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/AccntList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },


      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
          this.uploadFile1 = null;
          this.editedItem.BUSINESS_REF = null;
        }else if(index == 1){
          this.previewImg2 = "";
          this.uploadFile2 = null;
          this.editedItem.CARD_REF = null;
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        if(index == 0){
          this.option.fixedNumber = [1920, 1080];
          this.option.autoCropWidth = 178;
          this.option.autoCropHeight = 100;
        }else{
          this.option.fixedNumber = [1080, 1920];
          this.option.autoCropWidth = 100;
          this.option.autoCropHeight = 178;
        }

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },      

      //이미지 선택시
      changefile(file, pindex){
        console.log("change file1 : " + file);
        console.log("change file2 : " + pindex);

        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.cropper_land.getCropData(data=>{
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }
          });
        });
      },


      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running' + this.progress_value);
                    break;
                }                
              },
              (error) => {
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })
      },      


      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      async loadpage(p){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

        this.loading = true;
        this.page = p;
        this.paging.setpage(this.page);
          this.$http.post(this.$host+'/AcntList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:token}})
          .then((result)=>{

            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;
                

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      //APIKEY 생성
      async createAPIKey(id){

        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }
        
        this.paging.setpage(this.page);
          this.$http.post(this.$host+'/createAPIKey',{
            LOCALID:id
          },{headers: { firetoken:token}})
          .then((result)=>{

            if(result.data.resultCode == 0){
              this.$alert.fire("APIKEY 생성이 완료되었습니다").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
              });
           }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("API 생성 권한이 없습니다.");
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },      

      initpage(){
        this.loadtimezone();
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false;
        this.dialog_pw  = false;
        this.pay_dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

          if(this.editedItem.GRADE == undefined || this.editedItem.GRADE.length < 1){
            this.$alert.fire("등급을 선택하세요");
            return;
          } 

          if(this.editedItem.TIMEZONE == null || this.editedItem.TIMEZONE == undefined || this.editedItem.TIMEZONE.length < 1){
            this.$alert.fire("시간대를 선택하세요");
            return;
          }

          if(this.editedItem.NAME.length < 1){
              this.$alert.fire("이름을 입력하세요");
              return;
          }
          if(this.editedItem.NAME.length > 10){
              this.$alert.fire("이름은 10자 이하로 입력하세요");
              return;
          }

          //로고 사진
          if(!this.isNullCheck(this.uploadFile1)){
            this.progress_dialog = true;            
            let imgpath;
            //신규인지 업데이트인지 체크
            if(!this.isNullCheck(this.editedItem.REFIMAGE)){
              imgpath  = this.editedItem.REFIMAGE;
            }else{
              let curdate = Date.now().toString();
              imgpath  = `logo/${curdate}.jpg`;
            }

            //대표 이미지 등록
            const uploadfile_ref1 = fref(this.$firestorage, imgpath);
            const metadata1 = { contentType: 'image/jpeg', resumable: false }; 
            let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile1, metadata1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$alert.fire("가로 로고 이미지 등록 오류 발생");
              return;
            } 
            this.uploadFile1 = [];
            /*
            try{
              if(!this.isNullCheck(this.editedItem.REFIMAGE)){
                const desertRef1 = fref(this.$firestorage, this.editedItem.REFIMAGE);
                await deleteObject(desertRef1);
              }
            }catch(err){
              console.log(err);
            }
            */
            this.editedItem.REFIMAGE = upload_cb1._location.path_;
          }

          if(!this.isNullCheck(this.uploadFile2)){
            this.progress_dialog = true;            
            let imgpath;
            //신규인지 업데이트인지 체크
            if(!this.isNullCheck(this.editedItem.REFIMAGE2)){
              imgpath  = this.editedItem.REFIMAGE2;
            }else{
              let curdate = Date.now().toString();
              imgpath  = `logo/${curdate}.jpg`;
            }

            //대표 이미지 등록
            const uploadfile_ref2 = fref(this.$firestorage, imgpath);
            const metadata2 = { contentType: 'image/jpeg', resumable: false }; 
            let upload_cb2 = await this.uploadFileStorage(uploadfile_ref2, this.uploadFile2, metadata2);
            if(upload_cb2 == null){
              this.progress_dialog = false;
              this.$alert.fire("세로 로고 이미지 등록 오류 발생");
              return;
            } 
            this.uploadFile2 = [];
            /*
            try{
              if(!this.isNullCheck(this.editedItem.REFIMAGE2)){
                const desertRef2 = fref(this.$firestorage, this.editedItem.REFIMAGE2);
                await deleteObject(desertRef2);
              }
            }catch(err2){
              console.log(err2);
            }
            */
            this.editedItem.REFIMAGE2 = upload_cb2._location.path_;
          }

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/AcntUpdate',{
                GRADE: this.editedItem.GRADE
              , NAME: this.editedItem.NAME == null || this.editedItem.NAME == undefined ? '' : escape(this.editedItem.NAME)
              , LOGO_IMG: this.editedItem.REFIMAGE == null || this.editedItem.REFIMAGE == undefined ? '' : escape(this.editedItem.REFIMAGE)
              , LOGO_IMG2: this.editedItem.REFIMAGE2 == null || this.editedItem.REFIMAGE2 == undefined ? '' : escape(this.editedItem.REFIMAGE2)
              , STATE: this.editedItem.STATE
              , API_STATE: this.editedItem.API_STATE
              , TIMEZONE: this.editedItem.TIMEZONE
              , PARENT_LOCALID: this.editedItem.PARENT_LOCALID
              , LOCALID: this.editedItem.LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

                
                this.$alert.fire("수정이 완료되었습니다").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });

        

        this.close()
      },      
      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //신규 추가
      newinsert(){
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.previewImg1 = "";
        this.previewImg2 = "";

        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);

        this.previewImg1 = this.editedItem.LOGO_IMG != null && this.editedItem.LOGO_IMG.length > 0 ? this.editedItem.LOGO_IMG : "";
        this.previewImg2 = this.editedItem.LOGO_IMG2 != null && this.editedItem.LOGO_IMG2.length > 0 ? this.editedItem.LOGO_IMG2 : "";

        this.dialog = true;
      },


      save_password(){
        //비밀번호 리셋 메일 발송
        this.$alert.fire('비밀번호를 재설정하기 위한 메일을 발송하시겠습니까?')
        .then(async (aresult) => {

          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/resetpassword',{
                  ACCNT: this.editedItem.ACCNT
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                  this.$alert.fire("메일 발송이 완료되었습니다").then(()=>{
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');

              }else{
                  this.$alert.fire(result.data.resultMsg);
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$alert.fire('메일 발송에 실패했습니다.');
            });
          }

        });
        
      },
      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>