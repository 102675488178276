<template>
  <div/>
</template>
<script>

export default {
  setup() {
    
  },
}
</script>

<!-- 
<template>
  <div>
    <div>
      <button @click="addText">Add Text</button>
      <button @click="clearCanvas">Clear Canvas</button>
      <button @click="print">print</button>
    </div>
    <canvas ref="canvasRef" width="800" height="600" style="border: 1px solid #ccc"></canvas>
  </div>
</template>

<script lang="ts">
import { markRaw } from "vue";
import { fabric } from "fabric";
import Component_Model from "@/nextts/model/Component_Model";
// eslint-disable-next-line no-unused-vars
import { reactive, ref } from '@vue/reactivity';

export default {

  setup(){
    const canvasRef = ref(null);
    return {canvasRef};
  },

  data() {
    return {
      canvas: null,
    };
  },

  methods: {

    initializeCanvas() {

      if (!this.canvasRef) {
        console.error("Canvas element not found!");
        return;
      }

      this.canvas = new fabric.Canvas(this.canvasRef, {
        backgroundColor: "#f3f3f3",
        selection: true,
      });

      // 초기 도형 추가
      const rect = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        fill: "blue",
        opacity: 1.0,
        angle: 0,
        selectable: true, // 선택 가능
        hasControls: true, // 크기 조정 및 회전 핸들 표시
        hasBorders: true, // 객체 경계선 표시
        lockScalingFlip: true, // 스케일링 시 반전 방지        
      });
      const cm1 = new Component_Model();
      rect.set("customModel" as any, cm1);
      this.canvas.add(markRaw(rect));
      

      const rect2 = new fabric.Rect({
        left: 300,
        top: 200,
        width: 100,
        height: 100,
        fill: "red",
        opacity: 1.0,
        angle: 0,
        selectable:false
      });
      const cm2 = new Component_Model();
      rect2.set("customModel" as any, cm2);
      this.canvas.add(markRaw(rect2));


      // 도형 추가
      const rect3 = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        fill: "blue",
        opacity: 1.0,
        angle: 0,
      });
      this.canvas.add(markRaw(rect3));      

      // Canvas 전체 이벤트 핸들링
      this.canvas.on("object:modified", (e) => {
        const target = e.target as fabric.Object;
        if (target) {
          this.syncModel(target);
        }
      });
      
    },

    syncModel(object: fabric.Object){
      
      //Fabric.js 객체에서 커스텀 프로퍼티 가져오기
      const model = object.get("customModel" as any) as Component_Model | undefined;      
      if (model) {
        model.width   = object.width ?? 0;
        model.height  = object.height ?? 0;
        model.alpha   = object.opacity ?? 1.0;
        model.rotate  = object.angle ?? 0;
        model.transx  = object.left ?? 0;
        model.transy  = object.top ?? 0;
        console.log(`Updated model:  model.transx`);
      }      
    },

    print(){



    },

    addText() {
      if (this.canvas) {
        const text = new fabric.Text("Hello Fabric.js!", {
          left: 150,
          top: 200,
          fontSize: 20,
          fill: "green",
        });
        this.canvas.add(text);
      }
    },
    
    clearCanvas() {
      if (this.canvas) {
        this.canvas.clear();
        this.canvas.setBackgroundColor("#f3f3f3", this.canvas.renderAll.bind(this.canvas));
      }
    },
  },

  mounted() {
    this.initializeCanvas();
  },

}
</script>

<style scoped>
button {
  margin-right: 10px;
}
</style>
-->