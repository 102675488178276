<template>

  <v-container fluid>

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="6" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>
    </v-row>

  <v-row dense no-gutters>
    <label class="total_label">{{`전체:${totalcnt} 용량:${totalsize}mb/${this.$storage.getStorageSync("LIMIT_SIZE")}mb` }}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        style="height: 70vh;"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.MCON_NAME>
          <a :class="sort_name=='MCON_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('MCON_NAME')">NAME</a>
        </template>

        <template v-slot:item.THUM_JSON="{ item }">  
          <v-row justify="center">
            <v-col align="center" cols="12">
              <template v-if="item.MCON_IDX != undefined">
              <ThumScreen
                :id="item.MCON_IDX"
                :screen_model="item.screen_model"
                :scalewidth="200" 
                />
              </template>
            </v-col>
          </v-row>
        </template>     

        <!--
        <template v-slot:item.MCON_IMG="{ item }">  
          <v-img
              width="200"
              :src="item.MCON_IMG"
              :lazy-src="item.MCON_IMG"
              cover
              class="bg-grey-lighten-2"
              />
        </template>     
        -->
        <template v-slot:item.MCON_NAME="{ item }">  
            <a>{{item.MCON_NAME}}</a>
        </template>     
        <template v-slot:item.NAME="{ item }">  
          {{ item.PARENT_NAME }}
        </template>     
        <template v-slot:item.MCON_STATE="{ item }">  
          {{ item.MCON_STATE == 1 ? '활성' : '비활성' }}
        </template>     
        <template v-slot:item.MCON_SHARE="{ item }">  
          {{ item.MCON_SHARE == 1 ? '공유' : '공유안함' }}
        </template>     
        <template v-slot:item.MCON_SIZE="{ item }">  
          {{ `${item.MCON_SIZE}mb` }}
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon v-if="item.RES_STATE == 0" class="mr-2" small @click="undo_item(item)">mdi-file-undo</v-icon>
            <v-icon v-if="item.RES_STATE == 0" small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

    components:{
      ThumScreen,
    },

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      totalsize : 0,       //전체 용량
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          SUBACCNT_IDX:'',
          LOCALID:'',
          SUBACCNT:'',
          PW:'',
          STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: '컨텐츠명', key: 'MCON_NAME',  sortable: false, align:'center' },
        {title: 'State', key: 'MCON_STATE',  sortable: false, align:'center' },
        {title: 'Share', key: 'MCON_SHARE',  sortable: false, align:'center' },
        {title: 'Size', key: 'MCON_SIZE',  sortable: false, align:'center' },
        {title: '삭제자', key: 'DEL_NAME',  sortable: false, align:'center' },
        {title: '삭제시간', key: 'DEL_DATE',  sortable: false, align:'center' },
        {title: '복구/삭제', key: 'actions', sortable: false, align:'center'}
      ],
      itemList: [],

      sort_name: 'MCON_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      SEARCH_CATEGORY_IDX:null,   
      categoryList:[],

      //추가 변수들
      STATELIST: [
          {name:'활성',     value:1}
        , {name:'비활성',   value:0}
      ],
      SHARELIST: [
          {name:'공유',       value:1}
        , {name:'공유안함',   value:0}
      ],
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      
      grade:0,

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {paging, searchbar, editor, contentStore};
    },

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID      = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/MyContentDeleteList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , SHOWTYPE: 1
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt  = result.data.totalcnt;
                this.totalsize = result.data.totalsize;

                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    let screen_model        = new Screen_Model();
                    screen_model.setStringValue(element.THUM_JSON);
                    screen_model.cacheFont();
                    element.screen_model = screen_model;
                    this.itemList.push(element); 
                });
                
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      


      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/MyContentDelete", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //아이템 삭제
      async undo_item(item){

        this.$alert.fire({showCancelButton: true, title:'해당 컨텐츠를 복구하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/MyContentRecover',{
                MCON_IDX:item.MCON_IDX
              , LOCALID: this.SEARCH_LOCALID
              , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('복구가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('복구가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('복구 실패');
            });      
          }
        });

      },


      //아이템 삭제
      async delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'리소스를 삭제하시겠습니까? 리소스 삭제시 복구할 수 없습니다.'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/MyContentResDelete',{
                MCON_IDX:item.MCON_IDX
              , LOCALID: this.SEARCH_LOCALID
              , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },





    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>