
import AniProperty_Model from "@/nextts/model/AniProperty_Model";
//기본 애니매이션 속성
class Animation_Model {

    aniname:string    = "animation";

    //애니매이션 속성
    delay:number      = 0;
    duration:number   = 0;  //1=1초
    movex:number      = 0;
    movey:number      = 0;
    centerx:number    = 50;
    centery:number    = 50;
    
    rotate:number     = 0;
    alpha:number      = 1;
    
    scale:number       = 1;
    scalex:number      = 1;
    scaley:number      = 1;

    //추가 파라메터
    rotatex:number    = 0;
    rotatey:number    = 0;
    skewx:number      = 0;
    skewy:number      = 0;

    ease:string       = "none"; //https://greensock.com/docs/v3/Eases
    easetype:string   = "out";  //in, out, inOut

    proplist:AniProperty_Model[];

    constructor(){
        this.proplist = [];
    }

    //JSON 스트링을 객체로 변환
    setStringValue(params:string):void{
        const temp      = JSON.parse(JSON.stringify(params));
        this.aniname    = temp.aniname;
        this.delay      = temp.delay;
        this.duration   = temp.duration;
        this.movex      = temp.movex;
        this.movey      = temp.movey;
        this.centerx    = temp.centerx;
        this.centery    = temp.centery;
        this.scalex     = temp.scalex ? temp.scalex : 1;
        this.scaley     = temp.scaley ? temp.scaley : 1;
        this.rotate     = temp.rotate;
        this.alpha      = temp.alpha;
        this.ease       = temp.ease;
        this.easetype   = temp.easetype;
        
        this.rotatex     = temp.rotatex ? temp.rotatex : 0;
        this.rotatey     = temp.rotatey ? temp.rotatey : 0;
        this.skewx       = temp.skewx ? temp.skewx : 0;
        this.skewy       = temp.skewy ? temp.skewy : 0;

        this.proplist.splice(0, this.proplist.length);
        if(temp.proplist != null && temp.proplist != undefined){
            temp.proplist.forEach((prop:string) => {
                console.log("prop : " + prop);
                const ap = new AniProperty_Model();
                ap.setStringValue(prop);
                this.proplist.push(ap);
            });
        }

    }    

}

export default Animation_Model
