
<!-- 커스텀 객체를 만들어서 타입스크립트와 자바스크립트를 혼용한 샘플 (moveable과 selecto때문에 타입스크립트 여기서 못씀) -->

<!-- moveable로 변형한 객체의 복원과 애니 테스트 (완료됨) -->

<!-- 관리자 애니매이션 생성 -->

<template>
  <v-container fluid class="background_basic">


    <!-- 수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">애니매이션 상태 설정</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>

              <v-row>
                <v-col cols="12">
                  <v-select v-model="editedItem.ANI_STATE" :items="STATELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" 
                    hide-details elevation="0"  
                    label="상태" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="d-flex">
                  <template v-for="category in editcategoryList" :key="category.CATEGORY_NAME">
                    <div class="d-flex mr-2">
                      <label style="font-size:12px; display: flex; align-items: center; cursor: pointer;">
                        <input type="checkbox" class="mr-1" v-model="category.USE_CHECK">
                        {{ category.CATEGORY_NAME }}
                      </label>
                    </div>
                  </template>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row dense>
      <v-col cols="4">
        <v-row dense class="pa-0">
          <v-col cols="6">
            <v-select 
              label="카테고리"
              v-model="SEARCH_CATEGORY_IDX" :items="categoryList" 
              item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
              outlined color="#808080" hide-details 
              density="compact" variant="outlined"
              @update:modelValue="loadpage(1)"
              />
          </v-col>
          <v-col cols="6" align="end">
            <v-btn  style="font-size:11px" @click="newani">New</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <!-- 테이블 -->
          <v-data-table
            class="datatablestyle"
            :headers="headers"
            :items="itemList"
            style="min-height:400px"
            no-data-text="데이터가 없습니다."
            hide-default-footer
            :items-per-page="pagerow"
            :search="search"  
            item-key="IDX"
            :loading="loading"
            :mobile="false"
            >
            <template v-slot:header.ANI_NAME>
              <a :class="sort_name=='ANI_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('ANI_NAME')">애니</a>
            </template>
            <template v-slot:header.CATEGORY_NAMES>
              <a :class="sort_name=='CATEGORY_NAMES'?'cheader_sortselect':'cheader_sort'" @click="customsort('CATEGORY_NAMES')">카테고리</a>
            </template>

            <template v-slot:item.ANI_NAME="{ item }">
              <a @click="edit_item(item)" style="cursor:pointer">{{item.ANI_NAME}}</a>
            </template>

            <template v-slot:item.CATEGORY_NAMES="{ item }">
              <template v-if="item.CATEGORY_NAMES == null || item.CATEGORY_NAMES.length == 0">
                <a @click="edit_category(item)" style="cursor:pointer;color:blue">편집</a>
              </template>
              <template v-else>
                <a @click="edit_category(item)" style="cursor:pointer;color:blue">{{item.CATEGORY_NAMES}}</a>
              </template>
            </template>
            <template v-slot:item.ANI_STATE="{ item }">  
              {{ item.ANI_STATE == 1 ? '활성' : '비활성' }}
            </template>     
    
            <!-- 
            <template v-slot:item.SHOWANI="{ item }">
              <AniComponent 
                style="cursor:pointer;" 
                :anidata="item" @click="edit_item(item)"
                />
            </template>
            -->
            <template v-slot:item.COPY="{ item }">  
              <v-icon class="mr-2" small @click="edit_copy(item)">mdi-content-copy</v-icon>
            </template>     
            <template v-slot:item.actions="{ item }">  
                <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
                <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
            </template>     
            <template v-slot:bottom></template>
          </v-data-table>
        </v-row>

        <!-- 하단검색 -->
        <searchbar ref="searchbar" @searchcallback="search_run"/>
        
        <!-- 하단페이징영역 -->
        <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  

      </v-col>

      <v-col cols="6">
        <div id="editarea" ref="editarea " class="editerSize infinite-viewer" >
          <div id="editdiv" class="editerDivSize viewport" style="width:100%;height:100%;"/>
        </div>
      </v-col>

      <v-col cols="2">
        <div style="width:100%;height:100%;position:fixed;right:0px;top:65px;width:240px;background:white">
          <Edit_Animation 
            :aniplaymodel="aniplay_model"
            @aniplay="aniplay" @anilistplay="groupaniplay" @aniliststop="stopani"
            @loadpage="loadpage(1)"
            />
        </div>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import AniComponent from '@/nextvue/preview/AniComponent.vue'
import InfiniteViewer from "infinite-viewer";
import Moveable from 'moveable';
import { getElementInfo } from 'moveable';
import Selecto from "selecto";
import gsap from "gsap";

//커스텀 컴포넌트
import Edit_Animation from '@/nextvue/infopanel/Edit_Animation.vue'
import Info_Position from '@/nextvue/infopanel/ComInfo_Position.vue'

//import { toCSS } from 'transformation-matrix';

// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs } from "firebase/firestore";
import Animation_Model from '@/nextts/model/Animation_Model'
import Component_Model from '@/nextts/model/Component_Model'
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import { reactive, ref } from '@vue/reactivity';
export default {

  components:{
    // eslint-disable-next-line vue/no-unused-components
    Edit_Animation, Info_Position, AniComponent
  },

  setup(){
    var searchbar = ref(null);
    var paging    = ref(null);

    let aniplay_model  = reactive(new AnimationPlay_Model());

    //let temp = new Component_Model();
    //temp.posx = 999;
    //let pos_model = reactive(temp);

    return{
      aniplay_model, searchbar, paging
    }

  },

  data:()=>({
    
    windowHeight:window.innerHeight-50,
    timelinelist:[],
    pos_model:null,

    //시작 종료 애니매이션 객체정보
    anielement:null,

    cm_moveable:null,
    cm_selecto:null,
    
    targets:[],       //selecto 라이브러리를 통해 선택된 컴포넌트들
    componentList:[], //추가된 모든 컴포넌트들
    cm_targets:[], 
    infiniteViewer:null,

    frameskip: 0,
    zoom : 1,

    undolist:[],
    redolist:[],
    copylist:[],

    SEARCH_CATEGORY_IDX:null,
    categoryList:[],
    itemList:[],
    editcategoryList: [],

    editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
    dialog:false,
    defaultItem: {
      ANI_STATE:0,
    },
    editedItem: {
    },

    //추가 변수들
    STATELIST: [
        {name:'활성',     value:1}
      , {name:'비활성',   value:0}
    ],


    //필수 페이징 파라메터
    totalcnt : 0,       //전체 아이템 개수
    page : 0,
    pagerow : 15,       //한 페이지에 보여줄 row수
    loading : false,    //로딩 표시

    //검색 파라메터
    sort_name: 'ANI_NAME',
    sort_type: true,    //true : DESC, false: ASC
    search_keyword : '',

    //테이블 정의
    headers: [
      {title: '애니', key: 'ANI_NAME', sortable: false, align:'center', class:"cheader_normal"},
      {title: '카테고리', key: 'CATEGORY_NAMES', sortable: false, align:'center', class:"cheader_normal"},
      {title: '상태', key: 'ANI_STATE', sortable: false, align:'center', class:"cheader_normal"},
      //{title: '미리보기', key: 'SHOWANI', sortable: false, align:'center', class:"cheader_normal"},
      {title: '복사', key: 'COPY', sortable: false, align:'center'},
      {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
    ],

  }),
  

  mounted(){

    if(this.$storage.getStorageSync("GRADE") < 80){
      this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
      this.$router.push("/Login");
      return;
    }

    //인피니티 뷰어 설정
    const viewer    = document.querySelector(".infinite-viewer");
    this.infiniteViewer = new InfiniteViewer(
        viewer,
        document.querySelector(".viewport"),
        {
          useMouseDrag: false,
          useWheelScroll: true,
          useAutoZoom: true,
          zoomRange: [0.1, 10],
          maxPinchWheel: 10,          
        }
    );
    
    //무브어블 셋팅 - 사용안함.
    //this.setMoveSelecForComponent();

    this.loadanicategory();

    const edit_div = document.getElementById("editdiv");
    
    //초기 애니매이션 타겟이 되는 객체 생성
    let tempcm    = new Component_Model("animation admin");
    tempcm.component_type = Component_Model.COMPONENT_ANITEST;
    tempcm.width  = 100;
    tempcm.height = 100;
    tempcm.posx   = 300;
    tempcm.posy   = 300;
    tempcm.rotate = 0;
    tempcm.transx = 300;
    tempcm.transy = 300;
    tempcm.text   = "";
    this.anielement = new EditBasic_Element(reactive(tempcm));
    edit_div.appendChild(this.anielement);

  },


  methods:{

    customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
    },

    //검색
    search_run(searchkeyword){
      console.log("search....");
      if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
        this.search_keyword = searchkeyword;
      }else{
        this.search_keyword = '';
      }
      //페이지 상세가 있어서 다시 백을 해야하는 경우
      //this.movepage(1); 
      this.loadpage(1);
    },      


    movepage(p){
      this.loadpage(p);
    },


    //다이얼로그 닫기
    close () {
      this.dialog     = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },


    //아이템복사
    edit_copy(item){
      
      this.$alert.fire({showCancelButton: true, title:'해당 애니매이션을 복사하시겠습니까?'})
      .then(async (aresult) => {
        if(aresult.isConfirmed){
          //토큰값 체크
          let token;
          try{
            token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
          }
          this.$http.post(this.$host+'/AnimationCopy',{
            ANI_IDX: item.ANI_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            if(result.data.resultCode == 0){
              this.loadpage(1);
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

        }
      });

    },


    //추가 저장
    async save () {

      //true, false를 1,0으로 변환
      this.editcategoryList.forEach(category=>{
        category.USE_CHECK = category.USE_CHECK ? 1 : 0;
      });

      //토큰값 체크
      let token;
      try{
        token = await this.$fireauth.currentUser.getIdToken();
      }catch(error){
        token = this.$storage.getStorageSync("FIRETOKEN");
      }

      console.log("tetst : " + JSON.stringify(this.editedItem));


      //수정
      this.loading = true;
      this.$http.post(this.$host+'/AniStateUpdate',{
            ANI_STATE: this.editedItem.ANI_STATE
          , CATEGORYTYPES: escape(JSON.stringify(this.editcategoryList))
          , ANI_IDX: this.editedItem.ANI_IDX
      },{headers: { firetoken:token}})
      .then((result)=>{
        this.loading = false;
        if(result.data.resultCode == 0){
          this.$alert.fire("수정이 완료되었습니다.").then(()=>{
            this.loadpage(this.page);
          });

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
          this.$emitter.emit('clearsession');
        }else{
            this.$alert.fire('수정이 실패했습니다.');
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        this.$alert.fire('수정이 실패했습니다.');
      });

      this.close();
    },          
    
    setMoveSelecForComponent(){

      console.log("setMoveSelecForComponent...");

      //selecto - 드래그 앤 드랍으로 컴포넌트 선택해주는 라이브러리
      const viewer    = document.querySelector(".infinite-viewer");

      if(this.cm_selecto == null){
        console.log("new selecto...");
        this.cm_selecto = new Selecto({
            //viewer,
            dragContainer: viewer,          //셀렉트 드래그가 가능한 영역
            selectableTargets: [".drag"],     //선택할 수 있는 타겟
            hitRate: 0,
            selectByClick: true,              //클릭으로 선택
            selectFromInside: false,
            toggleContinueSelect: ["shift"],  //쉬프트로 추가 선택/해제
            ratio: 0,
            getElementRect: getElementInfo
        });

        this.cm_selecto.on("dragStart", e => {

          console.log("cm_selecto dragStart...");

          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }

          const target = e.inputEvent.target;
          if(this.cm_moveable.isMoveableElement(target) || this.cm_targets.some(t => t === target || t.contains(target))
          ){
            e.stop();
          }

        }).on("select", e => {

          //드래그로 선택된 경우, 클릭한 경우

          //space바를 누르고 있다면 무시
          if(this.cm_moveable == null || this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }
          
          var elements   = this.cm_selecto.getSelectedTargets();
          this.cm_targets         = elements;
          this.cm_moveable.target = elements;

        }).on("selectEnd", e => {

          if(e.isDragStart) {
              e.inputEvent.preventDefault();
              setTimeout(() => {
                this.cm_moveable.dragStart(e.inputEvent);
              });
          }else{
            this.cm_targets = e.selected;
            this.cm_moveable.target = this.cm_targets;
          }

        });            

      }

      if(this.cm_moveable == null){
        console.log("new moveable...");
        this.setMoveable();  
      }

    },

    //moveable 셋팅
    setMoveable(){

      //moveable - 이동, 회전, 리사이즈 에디터 라이브러리
      const editdiv = document.getElementById("editdiv");
      this.cm_moveable = new Moveable(editdiv, {
        
        draggable: true,   //스크린과 교차로 써야되기 때문에 초기에 false줌.
        rotatable: true,
        resizable: true,
        scalable: true,

        origin: false,      //중심부 렌더링
        keepRatio: false,   //리사이즈시 비율 유지 유무
        snapThreshold: 5,

        snappable: true,
        isDisplaySnapDigit: true,
        isDisplayInnerSnapDigit: false,

        snapGap: true,
        snapDirections: {"top":true,"left":true,"bottom":true,"right":true,"center":true,"middle":true},
        elementSnapDirections: {"top":true,"left":true,"bottom":true,"right":true,"center":true,"middle":true},
        snapDigit: 0,      
        maxSnapElementGuidelineDistance: null,
        //elementGuidelines:[".drag"],
        //bounds: {"left":0,"top":0,"right":1920,"bottom":1080},
      }).on("dragStart", e=>{
        e.set([e.target.model.transx, e.target.model.transy]);      

      }).on("drag", e => {
        e.target.model.transx = Math.floor(e.beforeTranslate[0]);
        e.target.model.transy = Math.floor(e.beforeTranslate[1]);
        e.target.model.posx   = Math.floor(e.beforeTranslate[0]);
        e.target.model.posy   = Math.floor(e.beforeTranslate[1]);
        e.target.updateTransElement();

      }).on("dragEnd", e => {
        //undo 추가
        if(e.isDrag){
          //이동한 경우만 저장
        }
      }).on("rotateStart", e => {
        e.set(e.target.model.rotate);

      }).on("rotate", e => {
        e.target.model.rotate = Math.floor(e.beforeRotate);
        e.target.updateTransElement();

      }).on("resizeStart", e => {
        e.setOrigin(["%", "%"]);
        if (e.dragStart) {
          e.dragStart.set([e.target.model.transx, e.target.model.transy]);
        }
      }).on("resize", e => {
        e.target.model.width  = Math.floor(e.width);
        e.target.model.height = Math.floor(e.height);
        e.target.model.transx = Math.floor(e.drag.beforeTranslate[0]);
        e.target.model.transy = Math.floor(e.drag.beforeTranslate[1]);
        //width와 height값이 중요하다. 이 정보가 있어야 나중에 복원이 가능함 매트릭스는 공간에 대한 변형 정보라 넓이, 높이 정보가 없음. 그래서 넓이, 높이 + 매트릭스 둘 다 가지고 있어야 함.
        e.target.updateTransElementAll();

      }).on("scale", e => {
        //스케일을 쓸필요가 있나? ratio를 쓰면 될거 같은데?
        console.log("scale : " + e.scale[0] + " / " + e.scale[1]);
      });

    },        


      newani(){
        
        this.aniplay_model.ani_idx = -1;
        this.aniplay_model.category_idx = -1;
        this.aniplay_model.aniplayname = "";
        this.aniplay_model.repeat = 0;
        this.aniplay_model.repeatDelay = 0;
        this.aniplay_model.yoyo = false;
        this.aniplay_model.anilist.splice(0, this.aniplay_model.anilist.length);

      },


      async edit_category(item){

        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/AniDetailTypes',{
          ANI_IDX: item.ANI_IDX
        },{headers: { firetoken:token}})
        .then(async (result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

            this.editedIndex = this.itemList.indexOf(item)
            this.editedItem = Object.assign({}, item);

            console.log("edititem: " + JSON.stringify(this.editedItem));

            //카테고리 선택부분 수정
            const list = result.data.resultData;
            this.editcategoryList.splice(0, this.editcategoryList.length);
            list.forEach(element => 
            {
              //1,0을 true, false로 변환
              element.USE_CHECK = element.USE_CHECK == 0 ? false : true;
              this.editcategoryList.push(element); 
            });
            this.dialog = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      //아이템 수정
      async edit_item(item){

        this.$http.post(this.$host+'/GuestAnimationDetail',{
          ANI_IDX:item.ANI_IDX,
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list      = result.data.resultData;
              this.aniplay_model.setStringValue(list[0].ANI_JSON);
              this.aniplay_model.ani_idx = list[0].ANI_IDX;
              this.aniplay_model.category_idx = list[0].CATEGORY_IDX;

              //재생
              this.groupaniplay(this.aniplay_model);

          }else{
            console.log("select error");
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          console.log("error : " + error);
        });

      },

      //아이템 삭제
      delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/AnimationDelete',{
              ANI_IDX:item.ANI_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  this.loadpage(1);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },   
      

      async loadanicategory(){
          console.log("loadanicategory...");
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.$http.post(this.$host+'/CategoryTypeList',{
                search_keyword: ''
              , CATEGORY_TYPE: 5
              , sort_name: "CATEGORY_SORT"
              , sort_type: "ASC"
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                
                const list = result.data.resultData;
                this.categoryList.splice(0, this.categoryList.length);   //데이터 초기화
                this.editcategoryList.splice(0, this.editcategoryList.length);
                this.categoryList.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
                list.forEach(element => {
                  this.categoryList.push(element);
                  this.editcategoryList.push(element);
                });
                
                this.SEARCH_CATEGORY_IDX = this.categoryList[0].CATEGORY_IDX;
                this.loadpage(1);

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      async loadpage(p){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/AnimationList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , CATEGORY_IDX: this.SEARCH_CATEGORY_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },



    addcomponent(){

      //컴포넌트 모델 생성 (크기, 위치 정보)
      var width  = Math.floor(Math.random() * 150)+50;
      var height = Math.floor(Math.random() * 150)+50;
      var posx = Math.floor(Math.random() * 700)+50;
      var posy = Math.floor(Math.random() * 560)+50;
      var rotate = Math.floor(Math.random() * 360);
    
      let tempcm    = new Component_Model("animation admin addcomponent");
      tempcm.width  = width;
      tempcm.height = height;
      tempcm.posx   = posx;
      tempcm.posy   = posy;
      tempcm.rotate = rotate;
      tempcm.transx = posx;
      tempcm.transy = posy;
      
      let edit_el = new EditBasic_Element(reactive(tempcm));

      const edit_div = document.getElementById("editdiv");
      edit_div.appendChild(edit_el);
      this.componentList.push(edit_el);

    },

    addcomponentFrame(model){
      const target = new EditBasic_Element(model);
      const edit_div = document.getElementById("editdiv");
      edit_div.appendChild(target);
      this.componentList.push(target);
    },    

    printinfo(){

    },

    aniplay(ani_model){

      console.log("ani play!");

      let move_posx   = Number(this.anielement.model.transx) + Number(ani_model.movex);
      let move_posy   = Number(this.anielement.model.transy) + Number(ani_model.movey);
      let move_rotate = Number(this.anielement.model.rotate) + Number(ani_model.rotate);
      
      let move_rotatex = Number(ani_model.rotatex);
      let move_rotatey = Number(ani_model.rotatey);
      let move_skewx   = Number(ani_model.skewx);
      let move_skewy   = Number(ani_model.skewy);

      const from = {x:this.anielement.model.transx, y:this.anielement.model.transy
                  , scaleX:1, scaleY:1
                  , rotationX:0, rotationY:0, rotation:this.anielement.model.rotate
                  , skewX:0, skewY:0
                  , alpha:1
                  };
      const to   = {delay:ani_model.delay, duration:ani_model.duration, x:move_posx, y:move_posy
                  , scaleX:ani_model.scalex, scaleY:ani_model.scaley
                  , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                  , skewX:move_skewx, skewY:move_skewy
                  , alpha:ani_model.alpha, ease:`${ani_model.ease}.${ani_model.easetype}` };
      //추가 커스텀 속성처리
      ani_model.proplist.forEach(prop=>{
        to[prop.propname] = prop.propvalue;
      });

      gsap.fromTo(this.anielement, from, to);

    },

    //셀렉토, 무브어블 제거
    screenMoveMode(){
      const viewer    = document.querySelector(".infinite-viewer");
      viewer.style.cursor = "move";

      //컴포넌트 모드인가
      this.cm_targets = this.cm_moveable.target;

      //컴포넌트용 moveable, selecto 제거
      this.infiniteViewer.useMouseDrag = true;  //인피니티 뷰어의 드래그 이동을 허용하여 스크린을 이동시킴
      if(this.cm_moveable != null) this.cm_moveable.destroy();
      if(this.cm_selecto != null){
        this.cm_selecto.destroy();
      } 
      this.cm_moveable = null;
      this.cm_selecto  = null;
    },

    //셀렉토, 무브어블 복구
    screenEditMode(){
      const viewer    = document.querySelector(".infinite-viewer");
      viewer.style.cursor = "default";
      this.infiniteViewer.useMouseDrag = false;
      //셀렉토랑 무브어블 복원
      this.setMoveSelecForComponent();
      if(this.cm_targets != null && this.cm_targets.length > 0){
        //기존에 컴포넌트를 선택했던 타겟이 0보다 크면 다시 컴포넌트 모드로 돌아가야한다.
        this.cm_moveable.target = this.cm_targets;
      }
    },

    animationComplete(){

      let move_posx   = Number(this.anielement.model.transx);
      let move_posy   = Number(this.anielement.model.transy);
      console.log(`pos2 : ${move_posx} / ${move_posy}`);

      for(const timeline of this.timelinelist){
        timeline.clear();
      }
      this.timelinelist.splice(0, this.timelinelist.length);

      //moveable 재생성
      //this.screenEditMode();

    },

    //애니 중지
    stopani(){
      for(const timeline of this.timelinelist){
        timeline.clear();
      }
      this.timelinelist.splice(0, this.timelinelist.length);
    },

    //전체 플레이
    groupaniplay(aniplay){
      
      if(aniplay == null || aniplay == undefined || aniplay.anilist.length == 0){
        return;
      }

      //moveable 제거
      //this.screenMoveMode();

      if(aniplay == null || aniplay == undefined || aniplay.anilist.length < 1){
        console.log("groupaniplay...1");
        return;
      }

      const timeline = gsap.timeline({repeat:Number(aniplay.repeat), repeatDelay:Number(aniplay.repeatDelay), yoyo:Boolean(aniplay.yoyo), onComplete:this.animationComplete});
      this.timelinelist.push(timeline);

      //let from = {x:this.anielement.model.transx, y:this.anielement.model.transy, rotate:this.anielement.model.rotate, alpha:this.anielement.model.alpha, scale:1}
      //timeline.from(from);

      //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
      let move_posx   = Number(this.anielement.model.transx);
      let move_posy   = Number(this.anielement.model.transy);
      let move_rotate = Number(this.anielement.model.rotate);

      let move_rotatex = 0;
      let move_rotatey = 0;
      let move_skewx   = 0;
      let move_skewy   = 0;

      //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
      //요요 사용시에는 이 초기값까지 리버스 하기 때문에 중간에 튀어버린다.
      if(!aniplay.yoyo){
        timeline.to(this.anielement, { duration:0, x:move_posx, y:move_posy
          , scaleX:this.anielement.model.scalex, scaleY:this.anielement.model.scaley
          , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
          , skewX:move_skewx, skewY:move_skewy        
          , alpha:this.anielement.model.alpha
          , transformOrigin: `${this.anielement.model.centerx}% ${this.anielement.model.centery}%`});  
      }else{
        timeline.to(this.anielement, { duration:0, x:move_posx, y:move_posy
          , scaleX:this.anielement.model.scalex, scaleY:this.anielement.model.scaley
          , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
          , skewX:move_skewx, skewY:move_skewy        
          , alpha:this.anielement.model.alpha 
          , transformOrigin: `${this.anielement.model.centerx}% ${this.anielement.model.centery}%`});  
      }

      // eslint-disable-next-line no-unused-vars
      aniplay.anilist.forEach((ani, index)=>{
        move_posx += Number(ani.movex);
        move_posy += Number(ani.movey);
        move_rotate += Number(ani.rotate);
        move_rotatex += Number(ani.rotatex);
        move_rotatey += Number(ani.rotatey);
        move_skewx += Number(ani.skewx);
        move_skewy += Number(ani.skewy);

        //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
        //const aniprop = { delay: index == 0 ? 0 : ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` };
        const aniprop = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                        , scaleX:ani.scalex, scaleY:ani.scaley
                        , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                        , skewX:move_skewx, skewY:move_skewy        
                        , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                        , transformOrigin: `${ani.centerx}% ${ani.centery}%` };
        //추가 커스텀 속성처리
        ani.proplist.forEach(prop=>{
            aniprop[prop.propname] = prop.propvalue;
        });
        timeline.to(this.anielement, aniprop);
        //console.log("nr : " + move_rotate);
        //timeline.to(this.anielement, { delay:ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` });
      });
      
    }

  },


}
</script>

<!-- background:repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px); -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.editerSize{
  width:100% !important;
  position: absolute;
  top:0px;
  bottom:0px;
  background: linear-gradient(to bottom, #2c3e50, #1a1a1a);
  overflow:hidden;
}



.editerDivSize{
  display: flex;
  flex-direction: column; /* 세로배치 */
}

.checkerboard {
  background-image: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
}
</style>