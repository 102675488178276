<!-- 애니매이션 Asset 선택용 팝업 -->
<template>
<v-row>

    <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="dialog_com" fullscreen="true" 
        >
        <v-card class="dialog_round pa-2">
        
            <v-card-text>
                <v-container fluid>

        <v-row dense>

            <v-col cols="5" >
                <v-row dense align="center" class="pa-0">
                    <v-col cols="6" align="center">
                        <label>애니매이션 선택</label>
                    </v-col>
                    <v-col cols="6">
                        <v-select 
                            label="카테고리"
                            v-model="SEARCH_CATEGORY_IDX_COM" :items="categoryList_com" 
                            item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
                            outlined color="#808080" hide-details 
                            density="compact" variant="outlined"
                            @update:modelValue="loadpage_com(1)"
                            />
                    </v-col>
                </v-row>
                <v-row>
                    <!-- 테이블 -->
                    <v-data-table
                        class="datatablestyle"
                        :headers="headers_com"
                        :items="itemList_com"
                        style="min-height:400px"
                        no-data-text="데이터가 없습니다."
                        hide-default-footer
                        :items-per-page="pagerow_com"
                        item-key="IDX"
                        height="100%"
                        :mobile="false"
                        >
                        <template v-slot:header.ANI_NAME>
                            <a :class="sort_name=='ANI_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('ANI_NAME')">애니</a>
                        </template>
                        <template v-slot:header.CATEGORY_NAMES>
                            <a :class="sort_name=='CATEGORY_NAMES'?'cheader_sortselect':'cheader_sort'" @click="customsort('CATEGORY_NAMES')">카테고리</a>
                        </template>
                        <template v-slot:item.ANI_NAME="{ item }">  
                            <a style="cursor:pointer;color:blue" @click="select_func(item)">{{ item.ANI_NAME }}</a>
                        </template>     
                        <template v-slot:item.SHOWANI="{ item }">
                            <AniComponent 
                                style="cursor:pointer;" 
                                :anidata="item" @click="aniplay(item)"
                                />
                        </template>
              
                        <template v-slot:item.play="{ item }">  
                            <v-icon small @click="aniplay(item)">mdi-play</v-icon>
                        </template>     
                        <template v-slot:bottom></template>
                    </v-data-table>
                </v-row>
                
                <!-- 하단검색 -->
                <searchbar ref="searchbar_com" @searchcallback="search_run_com"/>

                <!-- 하단페이징영역 -->
                <pagingbar ref="paging_com" :page="page_com" @loadpage="movepage_com"/>

            </v-col>

            <v-col cols="7">
                <div id="edit_div" class="viewport" style="border: 1px red solid; width:100%;height:100%; overflow:hidden;background: linear-gradient(to bottom, #2c3e50, #1a1a1a);"/>
            </v-col>

        </v-row>
  
        </v-container>
        </v-card-text>

        <v-card-actions>
            <v-row justify="center">
                <v-btn class="dialog_cancel_btn" rounded large @click="close_com">닫기</v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
    </v-dialog>     
</v-row>
</template>

<script>
import AniComponent from '@/nextvue/preview/AniComponent.vue'
import gsap from "gsap";
import Component_Model from '@/nextts/model/Component_Model'
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
import { ref, reactive } from '@vue/reactivity';
import wait from 'waait';
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model';
export default {
       
    components:{
        // eslint-disable-next-line vue/no-unused-components
        AniComponent
    },
    
    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{
        comname: String,
    },
    
    
    watch:{
        dialog_com: async function(v1){
            //console.log("v1 : " + v1 + " / v2 : " + v2);
            if(v1){
                //팝업이 뜬 경우
                await wait(250);
                this.page_com = 1;
                this.loadanicategory_com();

                const edit_div = document.getElementById("edit_div");
                let tempcm    = new Component_Model();
                tempcm.width  = 100;
                tempcm.height = 100;
                tempcm.component_type = Component_Model.COMPONENT_ANITEST;
                tempcm.posx   = 0;
                tempcm.posy   = 0;
                tempcm.rotate = 0;
                tempcm.transx = 250;
                tempcm.transy = 200;
                this.anielement = new EditBasic_Element(reactive(tempcm));
                edit_div.appendChild(this.anielement);

            }
        },
    },
    

    setup(){
        console.log("setup com accnt");
        const searchbar_com = ref(null);
        const paging_com    = ref(null);
        return {searchbar_com, paging_com};
    },

    data: ()=> ({

        anielement:null,

        dialog_com : false,
        load_flag : false,
        sort_name_com: 'REG_DATE',
        sort_type_com: true,    //true : DESC, false: ASC
        
        //테이블 정의
        headers_com: [
            {title: '애니', key: 'ANI_NAME', width:150, sortable: false, align:'center', class:"cheader_normal"},
            {title: '카테고리', key: 'CATEGORY_NAMES', width:250, sortable: false, align:'center', class:"cheader_normal"},
            {title: '미리보기', key: 'SHOWANI', width:150, sortable: false, align:'center', class:"cheader_normal"},
            {title: '재생', key: 'play', width:80, sortable: false, align:'center', class:"cheader_normal"},
        ],

        page_com : 0,
        pagerow_com: 20,
        maxpage_com : 0,       //전체 페이지 개수
        categoryList_com:[],
        SEARCH_CATEGORY_IDX_COM:null,
        itemList_com: [],
        search_keyword_com : '',
    }),

    mounted(){
        console.log("com accnt mount");
    },


    methods: {


        //전체 플레이
        aniplay(item){

            const aniplay = new AnimationPlay_Model();
            aniplay.setStringValue(item.ANI_JSON);

            if(aniplay == null || aniplay == undefined || aniplay.anilist.length < 1){
                console.log("groupaniplay...1");
                return;
            }

            let timeline = gsap.timeline({repeat:Number(aniplay.repeat), repeatDelay:Number(aniplay.repeatDelay), yoyo:Boolean(aniplay.yoyo)});
            //let from = {x:this.anielement.model.transx, y:this.anielement.model.transy, rotate:this.anielement.model.rotate, alpha:this.anielement.model.alpha, scale:1}
            //timeline.from(from);

            //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
            let move_posx   = Number(this.anielement.model.transx);
            let move_posy   = Number(this.anielement.model.transy);
            let move_rotate = Number(this.anielement.model.rotate);
            
            let move_rotatex = 0;
            let move_rotatey = 0;
            let move_skewx   = 0;
            let move_skewy   = 0;

            //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
            timeline.to(this.anielement, { duration:0, x:move_posx, y:move_posy
                , scaleX:this.anielement.model.scalex, scaleY:this.anielement.model.scaley
                , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                , skewX:move_skewx, skewY:move_skewy        
                , alpha:this.anielement.model.alpha });
            
            // eslint-disable-next-line no-unused-vars
            aniplay.anilist.forEach((ani, index)=>{
                move_posx += Number(ani.movex);
                move_posy += Number(ani.movey);
                move_rotate += Number(ani.rotate);
                move_rotatex += Number(ani.rotatex);
                move_rotatey += Number(ani.rotatey);
                move_skewx += Number(ani.skewx);
                move_skewy += Number(ani.skewy);

                //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
                const aniprop = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                                , scaleX:ani.scalex, scaleY:ani.scaley
                                , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                                , skewX:move_skewx, skewY:move_skewy        
                                , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
                                
                ani.proplist.forEach(prop=>{
                    aniprop[prop.propname] = prop.propvalue;
                });
                timeline.to(this.anielement, aniprop);
            });
            

        },

        update_dialog(){

            console.log("update dialog...");

        },
        
        searchbar_run(){
            console.log("searchbar run : " + this.search_keyword);
            this.$emit('searchcallback', this.search_keyword);
        },

        movepage_com(p){
            this.loadpage_com(p);
        },

        async loadanicategory_com(){
          console.log("loadanicategory...");
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.$http.post(this.$host+'/CategoryTypeList',{
                search_keyword: ''
              , CATEGORY_TYPE: 5
              , sort_name: "CATEGORY_SORT"
              , sort_type: "ASC"
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                const list = result.data.resultData;
                this.categoryList_com.splice(0, this.categoryList_com.length);   //데이터 초기화
                this.categoryList_com.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
                list.forEach(element => {
                  this.categoryList_com.push(element); 
                });
                
                this.SEARCH_CATEGORY_IDX_COM = this.categoryList_com[0].CATEGORY_IDX;
                this.loadpage_com(1);

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      //검색
      search_run_com(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword_com = searchkeyword;
        }else{
          this.search_keyword_com = '';
        }
        this.loadpage_com(1);
      },      

        showpopup_com(){
            this.dialog_com = true;
            this.load_flag = false;
        },

        //다이얼로그 닫기
        close_com () {
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택취소
        clear_com(){
            this.$emit('cancelcallback', '')
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택
        select_func(select_item) {
            this.$emit('callback', select_item);
            //콜백 호출 후 닫아야함.
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },              

        async loadpage_com(p){

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.page_com = p;
            this.paging_com.setpage(this.page_com);
            this.$http.post(this.$host+'/AnimationComList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name:this.sort_name_com
                , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
                , page: this.page_com
                , CATEGORY_IDX: this.SEARCH_CATEGORY_IDX_COM
                , pagerow: this.pagerow_com
            },{headers: { firetoken:token}})
            .then((result)=>{
                
                if(result.data.resultCode == 0){

                    this.totalcnt_com = result.data.totalcnt;
                    this.paging_com.makepaging(this.totalcnt_com, this.pagerow_com);
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                    list.forEach(element => {
                        this.itemList_com.push(element); 
                    });

                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },        


    }

}
</script>

<style scoped>

.checkerboard {
    background-image: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
}

  
</style>