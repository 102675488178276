
import Component_Model from  '@/nextts/model/Component_Model';
import Play_Interface from '@/nextts/play/Play_Interface';
import gsap from "gsap";
import moment from 'moment'

//기본 데이터 속성
class Play_DateTime extends HTMLElement implements Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;
    switch_timer:NodeJS.Timeout|null = null;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Text init");
        //커스텀 api등을 통해야 하는 경우에는 분기하고

        //컴포넌트 시작
        this.playAni();

    }

    exit(): void {
        
        if(this.switch_timer != null){
            clearInterval(this.switch_timer);
        }

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            //첫번째 delay는 여기서 사용했기 때문에 runAni에서는 첫번째 deplay는 무조건 0으로 처리해야한다.
            setTimeout(() => {
               this.drawView();
               this.runAni();
            }, this.model.aniplaymodel.anilist[0].delay * 1000);

        }else{
            //애니 없음
            this.drawView();

            //애니가 없으면 바로 스위처 실행
            this.startSwitcher();            
        }

    }

    //애니매이션 실행
    runAni(){
        //console.log("Play Text run ani");
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);

        let move_rotatex = 0;
        let move_rotatey = 0;
        let move_skewx   = 0;
        let move_skewy   = 0;

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            move_rotatex += Number(ani.rotatex);
            move_rotatey += Number(ani.rotatey);
            move_skewx += Number(ani.skewx);
            move_skewy += Number(ani.skewy);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy
                                                            , scaleX:ani.scalex, scaleY:ani.scaley
                                                            , rotationX:move_rotatex, rotationY:move_rotatey, rotation:move_rotate
                                                            , skewX:move_skewx, skewY:move_skewy        
                                                            , alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
        });        

        this.timeline.eventCallback("onComplete", ()=>{
            //애니가 끝나면 콜백 실행
            this.startSwitcher();
        });       
    }


    drawView(){

        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.width  = this.model.width+"px";
        this.style.height = this.model.height+"px";
        this.style.opacity          = String(this.model.opacity);
        
        this.style.fontFamily   = this.model.textfont;
        this.style.fontSize     = this.model.textsize+'px';
        this.style.color        = this.model.textcolor;
        this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
        //텍스트 세로 정렬
        this.style.display          = "flex";
        this.style.flexDirection    = "column";
        this.style.lineHeight       = "120%";
        this.style.justifyContent   = this.model.textverti_align;       //top, center, end
        
        this.style.letterSpacing    = this.model.letterSpacing+"px" 
        this.style.lineHeight       = this.model.lineHeight+"%"     
        
        //언더라인
        if(this.model.textUnderline == 0){
            this.style.textDecoration = "none";
        }else{
            this.style.textDecoration = "underline";
        }
        
        //볼드
        if(this.model.textBold == 0){
            this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
        }else{
            this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
        }

        //이탤릭
        if(this.model.textItalic == 0){
            this.style.fontStyle = "normal";
        }else{
            this.style.fontStyle = "italic";
        }
        
        //그림자 우측, 아래, 블러범위, 색상
        if(this.model.shadow_use){
            this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
        }else{
            this.style.textShadow = "";
        }

        if(this.model.outline_use){
            this.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
        }else{
            this.style.webkitTextStroke = "";
        }

        if(this.model.date_type.length > 0){
            this.innerText = moment(new Date().toString()).format(this.model.date_type);
        }

        this.style.backgroundClip  = "";
        this.style.webkitBackgroundClip = "";
        this.style.backgroundImage = "";
        this.style.color = this.model.textcolor;
        this.style.webkitTextFillColor = this.model.textcolor;

        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }


    //스위처 시작
    startSwitcher(){
        if(this.model == null || this.model == undefined){
            return null;
        }
        this.switch_timer = setInterval(()=>{
            console.log("setInterval nextSwitcher.....");
            this.updateDatetime();
        }, 1000);        
    }    

    updateDatetime(){
        if(this.model == null || this.model == undefined){
            return;
        }
        if(this.model.date_type.length > 0){
            this.innerText = moment(new Date().toString()).format(this.model.date_type);
        }
    }


}

export default Play_DateTime
customElements.define('play-datetime', Play_DateTime);