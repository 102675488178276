<template>
<div id="app">
  <v-app id="inspire">

    <template v-if="showflag">
      <!-- 로그인상태 -->
      <v-navigation-drawer
        temporary
        clipped 
        fixed
        v-model="drawer"
        app
        color="#ffffff"
        >
          <v-list>
          <template v-for="menu in menulist">
            <template v-if="menu.sublist.length > 0">
              <!-- 그룹 메뉴 -->
              <v-list-group
                :key="menu.MENU_IDX" 
                :value="menu.MENU_IDX"
                no-action
                color="#000000"
                >
                <template v-slot:activator="{ props }">
                  <!-- 그룹 메뉴 타이틀 -->
                  <v-list-item  :key="menu.MENU_IDX" v-bind="props"
                                 >
                    <v-list-item-title 
                      prepend-icon="icon"
                      :key="menu.MENU_IDX">{{menu.MENU_NAME}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <!-- 서브 메뉴 -->
                <v-list-item-content>
                  <v-divider></v-divider>
                  <v-list-item 
                    v-for="subitem in menu.sublist" 
                    :key="subitem.MENU_IDX"
                    :value="subitem"
                    color="#6E00FA"
                    style="padding-left:24px !important"
                    @click="callrouteName(subitem.ROUTE, subitem.GRADE, subitem.MENU_NAME)">
                    <template v-slot:prepend>
                      <v-icon >{{subitem.MENUICON}}</v-icon>
                    </template>
                    <v-list-item-title :key="subitem.MENU_IDX">{{subitem.MENU_NAME}}</v-list-item-title>
                  </v-list-item>
                <v-divider></v-divider>
                </v-list-item-content>
              </v-list-group>


            </template>
            <template v-else>
              <!-- 단일 메뉴 :class="menu.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'" :color="menu.ROUTE === ROUTERNAME ? '#ffffff' : '#707070'" -->
              <v-list-item  :key="menu.MENU_IDX" 
                            :value="menu"
                            color="#6E00FA"
                            @click="callrouteName(menu.ROUTE, menu.GRADE, menu.MENU_NAME)">
                <template v-slot:prepend>
                  <v-icon >{{menu.MENUICON}}</v-icon>
                </template>
                <v-list-item-title :key="menu.MENU_IDX">
                  {{menu.MENU_NAME}}
                </v-list-item-title>
              </v-list-item>
            </template>

          </template>

        </v-list>
        <v-divider></v-divider>
      </v-navigation-drawer>
      
      <v-app-bar app fixed clipped-left :hidden="hide_title" 
                style="background-color:#ffffff;color:#212121;">
        <v-app-bar-nav-icon style="color:#808080" @click.stop="toggleDrawer"></v-app-bar-nav-icon>

        <label class="hidden-sm-and-down barotitle" @click="gohome">BARO</label>

        <!-- 
        <v-avatar class="ml-5" :tile="true" width="70" height="38" @click="gohome">
          <img :src="require('@/assets/logo.png')" alt="logo">
        </v-avatar>
        -->
        <v-spacer/>

        <template v-if="$vuetify.display.mdAndUp">
          <v-btn variant="text" class="top_menu_text">추천디자인</v-btn>
          <v-btn variant="text" class="top_menu_text">활용법</v-btn>
          <v-btn variant="text" class="top_menu_text" @click="goprice">가격</v-btn>
          <v-btn variant="text" class="top_menu_text">사용자 가이드</v-btn>
        </template>
        
        <v-spacer/>

        <!-- 사용자 아이콘 및 이름 PC에서만 보여줄 것 -->
        <template v-if="$vuetify.display.mdAndUp">
          <v-avatar v-if="logo_img != undefined && logo_img.length > 0" size="30" @click="gomypage">
            <v-img 
              sizes="20"
              :src="logo_img"
              />
          </v-avatar>
          <v-avatar v-if="logo_img == undefined || logo_img.length == 0" size="30">
            <v-icon size="20">mdi-account-circle</v-icon>
          </v-avatar>
          <label @click="logout" style="cursor:pointer" class="top_profile_text ml-2">{{ this.$storage.getStorageSync("NAME") }}</label>
          <v-icon @click="logout" size="20" color="white">mdi-chevron-right</v-icon>
        </template>
        
        <v-btn text color="#808080" @click="logout">로그아웃 ></v-btn>

      </v-app-bar>
    </template>
    <template v-else>
      <!-- 로그아웃상태 -->
      <v-navigation-drawer
        v-model="drawer"
        temporary
        color="#ffffff"
        fixed
        >
        <v-list
          nav
          dense
          >
          <v-list-item-group
            active-class="border"
            >
            <v-list-item :class="ROUTERNAME === 'Welcome' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Welcome' ? 'selecttext' : 'normaltext'" @click="gohome">NecSquare</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'Introboard' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Introboard' ? 'selecttext' : 'normaltext'" >서비스소개</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'AreaInfoList' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'AreaInfoList' ? 'selecttext' : 'normaltext'" >공지사항</v-list-item-title>
            </v-list-item>
            <v-list-item :class="ROUTERNAME === 'Freeboard' ? 'selectlist' : 'normallist'">
              <v-list-item-title :class="ROUTERNAME === 'Freeboard' ? 'selecttext' : 'normaltext'" >사용방법</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>    

      <v-app-bar app fixed clipped-left
        style="background-color:#ffffff;color:#212121;">
        <v-app-bar-nav-icon style="color:#808080" @click.stop="toggleDrawer"></v-app-bar-nav-icon>
        <label class="hidden-sm-and-down barotitle" @click="gohome">BARO</label>
        <v-spacer/>
        <template v-if="$vuetify.display.mdAndUp">
          <v-btn variant="text" class="top_menu_text">추천디자인</v-btn>
          <v-btn variant="text" class="top_menu_text">활용법</v-btn>
          <v-btn variant="text" class="top_menu_text" @click="goprice">가격</v-btn>
          <v-btn variant="text" class="top_menu_text">사용자 가이드</v-btn>
        </template>
        <v-spacer/>
        
        <v-btn @click="gologin" small rounded color="#ffffff" style="color:#808080" >로그인<v-icon right >mdi-chevron-right</v-icon></v-btn>
        
        
      </v-app-bar>
    </template>

    <v-main style="background:#f8f8f8" :style="hide_title ? `padding-top:60px` : ``">
      <router-view @toggle-drawer="toggleDrawer" :key="$route.fullPath"/> <!-- 같은 vue에 파라메터만 다른 경우에도 새로 읽기하려면 fullpath 처리 -->
    </v-main>

    <!-- 
    <v-footer 
      style="background:#191f28;max-height:280px !important; min-width:100% !important;" 
      class="d-flex flex-column align-center justify-start">
      
        <v-col cols="12" md="8" class="mt-3">
          <div class="footer_text1" style="margin-bottom:10px">(주)넥스퀘어</div>
          <div class="footer_text2">대표자: 라현성 | 사업자등록번호: 228-87-02062</div>
          <div class="footer_text2">통신판매업 신고번호: 제 2024-화성동탄-1282호</div>
          <div class="footer_text2">경기도 화성시 동탄기흥로 590 센트럴에이스타워 B동 603호</div>
          <div class="footer_text2">전화번호: 070-8098-9102</div>
          <div class="footer_text1" style="margin-top:10px">Copyright 2024 ©Necsquare Co.,Ltd. All Right Reserved.</div>
          <v-divider class="mt-3 mb-3" color="white"/>
          <div class="footer_text2"><a>이용약관</a><a class="ml-5">개인정보 처리 방침</a><a class="ml-5">저작권 안내</a></div>
          <div style="height:20px"/>
        </v-col>
      
    </v-footer>
    -->

    
  </v-app>
  
</div>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
export default {

  data () {
    return {
      
      /*
        http: getCurrentInstance()?.appContext.config.globalProperties.$http
      , host: getCurrentInstance()?.appContext.config.globalProperties.$host
      , alert: getCurrentInstance()?.appContext.config.globalProperties.$alert
      , emitter: getCurrentInstance()?.appContext.config.globalProperties.$emitter
      */

        drawer: false
      , showflag: false //사이드 메뉴와 탑바의 보여주기 유무
      , login_flag : false
      , menulist : []
      , ROUTERNAME: ''
      , db : null
      , group: null

      , logo_img:""
      
      , unauth:null
      , untoken:null
      , unuserinfo:null

      , hide_title: false      

    }
  },

  created(){

    //로그인 전역 이벤트 수신
    this.$emitter.on('loginresult', this.selectmenu);

    //풀사이즈 이벤트 수신
    this.$emitter.on('setfullsize', this.setfullsize);

    //화면이동 이벤트 수신
    this.$emitter.on('movepageresult', this.movepage);

    //세션 초기화 이벤트 수신
    this.$emitter.on('clearsession', this.clearsession);

    //타이틀
    this.$emitter.on('hidetitle', this.hidetitle);

    //이건 사용 안해도 되자나?
    this.unauth = this.$fireauth.onAuthStateChanged(async (user) => {
      console.log("onAuthStateChanged time : " + new Date());
      if(user){
        //console.log("onAuthStateChanged user : " + JSON.stringify(user));
        /*
        if(user.getIdTokenResult){
          user.getIdTokenResult().then((idTokenResult) => {
            console.log("claims : " + JSON.stringify(idTokenResult.claims));
          });
        }
        if(user.email){
          this.createCustomtoken(user.uid);
        }
        */
      }
    });    

    //id토큰이 갱신되면 호출됨. 저장해야함.
    //로그인시, 회원가입시, 토큰갱신시, 새로고침시
    this.untoken = this.$fireauth.onIdTokenChanged(async (user)=>{
      
      console.log("onIdTokenChanged time : "+ new Date());

      if(user){

        const idTokenResult = await user.getIdTokenResult();  //claims값 보기 위해서 요청, true를 쓰면 절대 안됨.
        console.log("idTokenResult : " + JSON.stringify(idTokenResult.claims));
        //만약 회원가입이 안된 경우면 회원가입 유도
        
        //이메일 인증처리 체크
        if(!idTokenResult.claims.email_verified){
          this.$alert.fire("인증메일을 확인해주세요.");
          return;
        }

        //사용자 동의 체크
        if(!idTokenResult.claims.AGREE || idTokenResult.claims.AGREE == 0){
          //사용자 동의가 안된경우 회원가입으로 유도해라. 이 경우도 잘못된 경우다.
          //this.$router.push('/ServiceAgree');
          return;
        }

        console.log("PARENT_LOCALID : " + idTokenResult.claims.PARENT_LOCALID);


        //id토큰 받기
        this.$storage.setStorageSync("LOCALID",     user.uid);
        this.$storage.setStorageSync("PARENT_LOCALID", idTokenResult.claims.PARENT_LOCALID);
        this.$storage.setStorageSync("ACCNT",       idTokenResult.claims.ACCNT);
        this.$storage.setStorageSync("NAME",        idTokenResult.claims.NAME);
        this.$storage.setStorageSync("GRADE",       idTokenResult.claims.GRADE);
        this.$storage.setStorageSync("TIMEZONE",    idTokenResult.claims.TIMEZONE);

        this.$storage.setStorageSync("SUB_NAME",       idTokenResult.claims.SUB_NAME);
        this.$storage.setStorageSync("LIMIT_CONNECT",  idTokenResult.claims.LIMIT_CONNECT);
        this.$storage.setStorageSync("LIMIT_SIZE",     idTokenResult.claims.LIMIT_SIZE);
        this.$storage.setStorageSync("LIMIT_CONTENT",  idTokenResult.claims.LIMIT_CONTENT);
        this.$storage.setStorageSync("LIMIT_SCREEN",   idTokenResult.claims.LIMIT_SCREEN);
        this.$storage.setStorageSync("LIMIT_PAGE",     idTokenResult.claims.LIMIT_PAGE);
        this.$storage.setStorageSync("LIMIT_POPUP",    idTokenResult.claims.LIMIT_POPUP);
        this.$storage.setStorageSync("LIMIT_TTS",      idTokenResult.claims.LIMIT_TTS);
        this.$storage.setStorageSync("LIMIT_MONITOR",  idTokenResult.claims.LIMIT_MONITOR);

        this.$storage.setStorageSync("FIRETOKEN",   idTokenResult.token);

        if(!this.$storage.hasKey("SEARCH_LOCALID")){
          //업체선택 값이 없으면 처음 셋팅
          this.$storage.setStorageSync("SEARCH_LOCALID",         user.uid);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  idTokenResult.claims.PARENT_LOCALID);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME",      idTokenResult.claims.NAME);
        }else{
          //무언가 값이 있다면 토큰이 갱신된 케이스라서 기존 선택값을 건들지 말 것.
        }


        //이동
        if(idTokenResult.claims.GRADE > 90){
          //로그인 성공 전역 이벤트 발송
          if(window.location.pathname == "/Login" || window.location.pathname == "/SubLogin" || window.location.pathname == "/ServiceAgree"){
            this.$router.push('/AccntList');
          }

          if(this.menulist.length == 0){
            this.$emitter.emit('loginresult');
          }
          
        }else{
          if(window.location.pathname == "/Login" || window.location.pathname == "/SubLogin" || window.location.pathname == "/ServiceAgree"){
            this.$router.push('/Mypage');
          }
          //로그인 성공 전역 이벤트 발송
          if(this.menulist.length == 0){
            this.$emitter.emit('loginresult');
          }
          
        }

        /*
        user.getIdToken().then((idtoken)=>{
          this.$storage.setStorageSync("FIRETOKEN",   idtoken);
        // eslint-disable-next-line no-unused-vars
        }).catch((error)=>{
        });
        */



      }
    })    

  },

  beforeUnmount(){
    
    //console.log("beforeUnmount...");
    window.removeEventListener('beforeunload', this.unloadevent);

    //로그인 전역 이벤트 수신 해제
    this.$emitter.off('loginresult');

    this.$emitter.off('setfullsize');

    //페이지 이동 이벤트 수신 해제
    this.$emitter.off('movepageresult');
    
    //세션 초기화 이벤트 해제
    this.$emitter.off('clearsession');

    //타이틀
    this.$emitter.off('hidetitle');

    //파이어베이스 리스너 해제
    if(this.unauth){
      this.unauth();
    }
    if(this.untoken){
      this.untoken();
    }
    if(this.unuserinfo){
      this.unuserinfo();
    }

  },
  
  mounted(){
    console.log("app mount...");
    window.addEventListener('beforeunload', this.unloadevent);

    //로딩바 숨기기
    document.getElementById('loading').style.display = 'none';

  },


  methods: {

    hidetitle(flag){
      this.hide_title = flag;
    },

    goprice(){
      this.$router.push("Price").then(()=>{
        this.ROUTERNAME = "Price";
      })
      .catch(()=>{
      });
    },

    toggleDrawer() {
      console.log("toggleDrawer");
      this.drawer = !this.drawer;
      this.$emitter.emit('drawerChanged', this.drawer);
    },

    setfullsize(flag){
      this.showflag = flag;
      this.$forceUpdate();
    },

    gohome(){
      this.$router.push("Welcome").then(()=>{
        this.ROUTERNAME = "Welcome";
      })
      .catch(()=>{
      });
    },


    gomypage(){

      //테스트
      const currentUser = this.$fireauth.currentUser;
      if (currentUser) {
        console.log(currentUser.uid); // 현재 사용자의 UID 출력
        currentUser.getIdTokenResult().then((idTokenResult) => {
          console.log(idTokenResult.claims);
        });        
      }


      this.$router.push('Mypage').then(()=>{
        this.ROUTERNAME = 'Mypage';
        this.$storage.setStorageSync("MENUNAME",   '마이페이지');
      })
      .catch(()=>{
      });
    },    

    gologin(){
      this.$router.push("Login").then(()=>{
        this.ROUTERNAME = "Login";
      })
      .catch(()=>{
      });
    },

    logout:function(){

        this.$alert.fire({title:"로그아웃 하시겠습니까?", showCancelButton:true})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            this.logout2();
          }
        });

    },

    //화면이동 전역 이벤트 수신
    async clearsession(){
      this.logout2();
    },

    async logout2(){
      
      if(this.unuserinfo){
        this.unuserinfo();
      }

      this.$storage.clearStorageSync("LOCALID");
      this.$storage.clearStorageSync("PARENT_LOCALID");
      this.$storage.clearStorageSync("ACCNT");
      this.$storage.clearStorageSync("GRADE");
      this.$storage.clearStorageSync("NAME");
      this.$storage.clearStorageSync("TIMEZONE");

      this.$storage.clearStorageSync("SUB_NAME");
      this.$storage.clearStorageSync("LIMIT_CONNECT");
      this.$storage.clearStorageSync("LIMIT_SIZE");
      this.$storage.clearStorageSync("LIMIT_CONTENT");
      this.$storage.clearStorageSync("LIMIT_SCREEN");
      this.$storage.clearStorageSync("LIMIT_PAGE");
      this.$storage.clearStorageSync("LIMIT_POPUP");
      this.$storage.clearStorageSync("LIMIT_TTS");
      this.$storage.clearStorageSync("LIMIT_MONITOR");

      this.$storage.clearStorageSync("FIRETOKEN");
      this.$storage.clearStorageSync("SEARCH_LOCALID");
      this.$storage.clearStorageSync("SEARCH_PARENT_LOCALID");
      this.$storage.clearStorageSync("SEARCH_ACCNT_NAME");
      try{
        await this.$fireauth.signOut();
      }catch(error){
        //console.log("firelogout : " + error);
      }
      this.selectmenu();
    },

    //로그인 전역 이벤트 수신
    selectmenu:function(){

      console.log("select menu....");

      //로그인이 되어있는 경우
      if(this.$storage.hasKey("LOCALID")){

        this.showflag   = true;
        this.drawer     = true;
        this.login_flag = true;
        
        //사용자 정보감지 리스너 등록
        if(this.unuserinfo){
          this.unuserinfo();
        }
        const userinfoRef = doc(this.$firestore, "company", this.$storage.getStorageSync("PARENT_LOCALID"), "users", this.$storage.getStorageSync("LOCALID"), "login", "info");
        this.unuserinfo = onSnapshot(userinfoRef, async (data)=>{

          if(data.data()){

            console.log(`update info : ${JSON.stringify(data.data())}`);

            //토큰을 갱신해야 하는지 체크
            let change_flag = false;
            if(
               this.$storage.getStorageSync("GRADE") != data.data().GRADE
            || this.$storage.getStorageSync("TIMEZONE") != data.data().TIMEZONE
            || this.$storage.getStorageSync("LIMIT_CONNECT") != data.data().LIMIT_CONNECT
            || this.$storage.getStorageSync("LIMIT_SIZE") != data.data().LIMIT_SIZE
            || this.$storage.getStorageSync("LIMIT_CONTENT") != data.data().LIMIT_CONTENT
            || this.$storage.getStorageSync("LIMIT_SCREEN") != data.data().LIMIT_SCREEN
            || this.$storage.getStorageSync("LIMIT_PAGE") != data.data().LIMIT_PAGE
            || this.$storage.getStorageSync("LIMIT_POPUP") != data.data().LIMIT_POPUP
            || this.$storage.getStorageSync("LIMIT_TTS") != data.data().LIMIT_TTS
            || this.$storage.getStorageSync("LIMIT_MONITOR") != data.data().LIMIT_MONITOR
            ){
              change_flag = true;
            }

            this.$storage.setStorageSync("NAME",           data.data().NAME);
            this.$storage.setStorageSync("GRADE",          data.data().GRADE);
            this.$storage.setStorageSync("TIMEZONE",       data.data().TIMEZONE);
            this.$storage.setStorageSync("SUB_NAME",       data.data().SUB_NAME);
            this.$storage.setStorageSync("LIMIT_CONNECT",  data.data().LIMIT_CONNECT);
            this.$storage.setStorageSync("LIMIT_SIZE",     data.data().LIMIT_SIZE);
            this.$storage.setStorageSync("LIMIT_CONTENT",  data.data().LIMIT_CONTENT);
            this.$storage.setStorageSync("LIMIT_SCREEN",   data.data().LIMIT_SCREEN);
            this.$storage.setStorageSync("LIMIT_PAGE",     data.data().LIMIT_PAGE);
            this.$storage.setStorageSync("LIMIT_POPUP",    data.data().LIMIT_POPUP);
            this.$storage.setStorageSync("LIMIT_TTS",      data.data().LIMIT_TTS);
            this.$storage.setStorageSync("LIMIT_MONITOR",  data.data().LIMIT_MONITOR);

            //그레이드가 바뀌면 메뉴를 다시 읽어야 한다.
            if(change_flag){
              console.log(`change token`);
              //firebase 토큰을 갱신
              this.reloadUserInfo(data.data().LOCALID);
              return; //이 아래의 메뉴읽기를 실행하면 안됨.
            }

          }
        });

        //메뉴읽기
        this.getmenulist();
        this.$forceUpdate();


      } else {

        console.log("logout....");

        //로그아웃
        this.showflag   = false;
        this.drawer     = false;
        this.login_flag = false;
        
        this.removemenulist();
        this.$router.push('Login');   //로그인 페이지로 이동
        this.$forceUpdate();

      }

    },


    //firebase의 클레임 갱신
    async reloadUserInfo(localid){

      //토큰값 체크
      let token;
      try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

      this.$http.post(this.$host+'/reloadUserInfo',{
        LOCALID: localid
      },{headers: { firetoken:token}})
      .then(async (result)=>{
        if(result.data.resultCode == 0){
          //정상적으로 클레임을 셋팅했으면 강제로 키를 갱신한다. (클레임이 키에 반영되서 나옴.)
          await this.$fireauth.currentUser.getIdToken(true);
          //메뉴읽기
          this.getmenulist();
          this.$forceUpdate();
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
      });        
        
    },                    

    //화면이동 전역 이벤트 수신
    movepage(router_name, menuname){
      //console.log("movepage : " + router_name + " / " + menuname);
      this.$router.push(router_name).then(()=>{
        this.ROUTERNAME = router_name;
        this.$storage.setStorageSync("MENUNAME",   menuname);
        this.$forceUpdate();
      })
      .catch(()=>{
      });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
    },

    callrouteName:function(router_name, grade, menuname){
        
        if(this.$storage.getStorageSync("GRADE") >= grade){
          this.$storage.setStorageSync("MENUNAME",   menuname);
          this.$router.push(router_name).then(()=>{
            this.ROUTERNAME = router_name;
          })
          .catch(()=>{
          });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
          
        }else{
          this.$alert.fire("사용할 수 없는 권한의 페이지입니다.").then(()=>{
            this.$router.back();
          });
        }

    },      


    getmenulist:async function(){

      console.log("getmenulist....");
        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

      this.$http.post(this.$host+'/getMenuList'
      ,{
      },{headers: { firetoken:token}})
      .then((result)=>{

        if(result.data.resultCode == 0){

          var glist = result.data.groupData;
          var slist = result.data.subData;

          glist.forEach(group => {
              group.sublist = [];
              slist.forEach(sub=>{
                if(group.MENU_NAME == sub.GROUPNAME){
                  group.sublist.push(sub);
                }
              });
              /*
              if(group.sublist.length == 0){
                group.sublist.push({IDX:group.IDX, COM_IDX:group.COM_IDX, MENUNAME:group.MENUNAME, ROUTE:group.ROUTE});
              }
              */
          });
          this.menulist.splice(0, this.menulist.length);
          glist.forEach(element => {
            this.menulist.push(element); 
          });

          //console.log("this.menulist : " + JSON.stringify(this.menulist));

        }else if(result.data.resultCode == 2){

          //로그인 필요
          this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.").then(()=>{
            this.clearsession();
          });

        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
      });

    },


    removemenulist:function(){
      //모두 제거
      this.menulist.splice(0, this.menulist.length);
    },

    //페이지 새로고침 또는 주소창에 다른주소 입력해서 빠져나갈 때 경고
    unloadevent:function(event){
      event.preventDefault();
      event.returnValue = "";
    },



  },

}
</script>

<style>

.background_basic{
  min-height: 100vh;
}

/*페이지명*/
.menunamestyle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;    
  color: #000000;
}
/*전체 페이지 수*/
.total_label{
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}

/* 데이터 테이블 정렬용 컬럼 헤더 스타일 */
.cheader_sortselect{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;    
  font-size: 14px !important;
  color:purple !important;
  text-underline-position: auto;
}

.cheader_sort{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color:#2e2e2e !important;
  text-underline-position: auto;
}

.cheader_normal{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color:#000000 !important;
  text-underline-position: auto;
  border-bottom: 2px red;
}

/* 사이드메뉴 */
.normallist{
  font-size: 18px;
  color: #aaaaaa;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 48px;
}
.normallist:hover{
  font-size: 18px;
  color: white;
  background-color: #9a55f5;
  border-radius: 10px 10px 10px 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 48px;
}

.normaltext{
  color:#aaaaaa !important;
}
.normaltext:hover{
  color:white !important;
}

/* 사이드메뉴 선택 라운드 */
.btn_round{
  white-space: normal;
  font-size: 17px !important;
  font-family: 'Pretendard' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #333333 ;
}
.btn_select_round{
  white-space: normal;
  font-size: 17px !important;
  font-family: 'Pretendard' !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.selectlist{
  font-size: 18px;
  color: white;
  background-color: #6e00fa;
  border-radius: 10px 10px 10px 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 48px;
}

.search_btn_freesize {
  color: #ffffff !important;
  background-color: #000000 !important;
  border: thin solid #ffffff !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  height: 43px !important;
  width: 98% !important;
  text-align: center !important;
  box-shadow: 2px 2px 5px #00000030 !important;
}


/* 팝업 타이틀 */
.dialog_title {
  color: #0A0A0A !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

/* 팝업  클로즈 버튼 */
.dialog_close_btn {
  color: #e5e5e5 !important;
  background-color: #ffffff !important;
  border-color:#e5e5e5 !important; 
  border-style:solid !important; 
  border-width:1px 1px 1px 1px !important;
}

/* 팝업 하단 버튼 */
.dialog_cancel_btn{
    color: #000000 !important;
    background-color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    height: 51px !important;
    width:100% !important;
    border: black 2px solid;

}

.dialog_btn {
    color: #ffffff !important;
    background-color: #000000 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    height: 51px !important;
    width:100% !important;
}

/* 리모트 컨트롤 버튼 */
.remote_btn{
  color: #000000 !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  height: 51px !important;
  width:100% !important;
  border: black 2px solid;
}


.paneloutline{
  border: solid 1px #808080;
}

/* 스크롤바 커스텀 디자인 */
.scrollBar::-webkit-scrollbar {
    width: 6px;  /* 스크롤바의 너비 */
}
.scrollBar::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    background: #afafaf80; /* 스크롤바의 색상 */
    border-radius: 6px;
}
.scrollBar::-webkit-scrollbar-track {
    background: #ffffff80;  /*스크롤바 뒷 배경 색상*/
}

.barotitle{
  font-family: 'Lemon';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  background: linear-gradient(90deg, #FF0000 0%, #6E00FA 78.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.top_menu_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0) !important;
}


.priceround{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  max-width: 350px;
}

.pricetitle{
  border-radius: 8px;
  background-color: #7930ff;
  color: #ffffff;
  width: 80%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
}

.pricevalue{
  font-size: 30px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricedesc{
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
}

.priceline{
  height: 1px;
  width: 90%;
  background-color: #7930ff;
}

.pricetext{
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: left;
}

.pricebtn{
  width:80%;
  height: 40px;
  color:white !important;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  font-size: 15px;
}


.h1_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h1_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h3_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h4_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #404040 !important;
  white-space: nowrap;
}

.img-hover-zoom {
  transition: transform 0.5s ease; /* 애니메이션 속도와 타입 설정 */
  display: inline-block; /* 이미지를 인라인 블록으로 설정 */
}
.img-hover-zoom:hover {
  transform: scale(1.1); /* 이미지 확대 비율 설정 */
}

.footer_text1{
  font-size:15px;
  color:#b0b8b8;
  
}
.footer_text2{
  font-size:13px;
  color:#ffffff;
}



.info_text{
  font-family: Inter;
  font-size: 12px;
  color: #303030
}

</style>
